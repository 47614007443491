import { AES, enc } from "crypto-js";

export const encryptData = (data) => {
  const encryptedData = AES.encrypt(
    data,
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return encryptedData;
};

export const decryptData = (encryptedData) => {
  const decryptedData = AES.decrypt(
    encryptedData,
    process.env.REACT_APP_SECRET_KEY
  ).toString(enc.Utf8);
  return decryptedData;
};
