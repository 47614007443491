import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toastAlert } from "../../utils/toastAlert";
import Axios from "../../axios/axiosInstance";
import { logoutUser } from "../../utils/logoutUser";
import { Nav } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const Header = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (location?.pathname === "/home") {
      setActiveLink("builder");
    } else if (location.pathname === "/rooms") {
      setActiveLink("rooms");
    } else if (location.pathname === "/advertisers") {
      setActiveLink("advertisers");
    } else {
      setActiveLink("");
    }
  }, [location]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleLogout = () => {
    Axios.get(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/logout`)
      .then((res) => {
        toastAlert("Logged out successfully", "success");
        sessionStorage.clear();
        logoutUser();
      })
      .catch((err) => {
        if (err.response && err.response.status !== 401) {
          toastAlert(err?.response?.data?.message, "error");
        }
      });
  };

  const [showToolTip, setShowToolTip] = useState(false);

  const handleRootClose = () => {
    console.log("close ");
    setShowToolTip(false);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="edit-delete">
        <span
          onClick={() => navigate("/change-password")}
          style={{ cursor: "pointer" }}
        >
          <img src="assets/images/lock.svg" alt="" /> Change Password
        </span>
        <span style={{ cursor: "pointer" }} onClick={handleLogout}>
          <img src="assets/images/logout.svg" alt="" /> Log Out
        </span>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className="header">
        <div className="container d-flex">
          <div className="logo">
            <Link to={"/home"}>
              {" "}
              <img src="assets/images/logo.svg" />
            </Link>
          </div>

          <div className="logout-btn d-flex align-items-center">
            <div className="builder">
              <Link
                to={"/advertisers"}
                className={activeLink === "advertisers" ? "active" : ""}
                onClick={() => handleLinkClick("rooms")}
              >
                Advertisers
              </Link>
              <Link
                to={"/home"}
                className={activeLink === "builder" ? "active" : ""}
                onClick={() => handleLinkClick("builder")}
              >
                Builders
              </Link>
              <Link
                to={"/rooms"}
                className={activeLink === "rooms" ? "active" : ""}
                onClick={() => handleLinkClick("rooms")}
              >
                Rooms
              </Link>
            </div>

            <OverlayTrigger
              onExit={() => setToggle(false)}
              trigger="click"
              placement="bottom-end"
              overlay={popover}
              rootClose={true}
              rootCloseEvent="click"
              onHide={handleRootClose}
            >
              <span
                className="admin"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                Admin
                {toggle ? (
                  <span>
                    <img alt="up" src="assets/images/up.svg" />
                  </span>
                ) : (
                  <span>
                    <img alt="down" src="assets/images/down.svg" />
                  </span>
                )}
              </span>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
