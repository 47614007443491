import { setCookie } from "./cookies";
export const logoutUser = () => {
  console.log("user");
  localStorage.clear();
  setCookie("DHSAPPauth", JSON.stringify({ isAuthenticated: false }), 7);
  setTimeout(() => {
    // window.location.replace("http://182.77.58.202/DreesHome/#/");
    window.location.replace("/");
  }, 1000);
};
