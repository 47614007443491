import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toastAlert } from "../../utils/toastAlert";
import useLoader from "../../component/loader/useLoader";
import Axios from "../../axios/axiosInstance";
import { getCookie, setCookie } from "../../utils/cookies";
import { encryptData } from "../../utils/encryptionDecryption";
const ChangePassword = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader, Loader } = useLoader();
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
  } = useForm({ mode: "onChange" });

  const [togglePasswordVisibility, setTogglePasswordVisibility] =
    useState(false);
  const [togglePasswordVisibility2, setTogglePasswordVisibility2] =
    useState(false);
  const [togglePasswordVisibility3, setTogglePasswordVisibility3] =
    useState(false);

  const password = watch("password", "");

  useEffect(() => {
    if (password) {
      trigger("confirmPassword");
    }
  }, [password]);

  useEffect(() => {
    const userData = JSON.parse(getCookie("suser_data"));
    console.log("use dtaa", userData);
    const isAuthenticated = JSON.parse(getCookie("DHAPPauth"))?.isAuthenticated;
    console.log(isAuthenticated);
    const isProfileSetupDone = JSON.parse(
      getCookie("DHAPPauth")
    )?.isProfileSetupDone;
    //  if (isAuthenticated && userData.token && isProfileSetupDone) {
    //    navigate("/home");
    //  } else if (isAuthenticated && userData.token && !isProfileSetupDone) {
    //    navigate("/profile-setup");
    //  }
  }, []);

  const onSubmit = (data) => {
    const userData = JSON.parse(getCookie("suser_data"));
    console.log("use dtaa", userData);
    // Handle form submission
    console.log(data);
    Axios.post(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/password`,
      {
        old_password: data?.currentPassword,
        new_password: data?.password,
        new_password_confirmation: data?.confirmPassword,
      }
    )
      .then((response) => {
        const isProfileSetupDone = response?.data?.user?.profile_setup_done;
        const encryptedEmail = encryptData(response?.data?.user?.email);
        const encryptedPassword = encryptData(data?.password);
        const userData = {
          email: encryptedEmail,
          password: encryptedPassword,
          rememberMe: true,
          token: response.data.token,
        };

        if (response.data.message) {
          toastAlert(response.data.message, "success");
        } else {
          toastAlert("Password updated successfully", "success");
        }

        if (userData?.rememberMe) {
          // Encrypt and store email and password as a single cookie
          setCookie("suser_data", JSON.stringify(userData), 7);
          setCookie("DHSAPPauth", JSON.stringify({ isAuthenticated: true }), 7);
          if (isProfileSetupDone === 0) {
            setCookie(
              "DHSAPPauth",
              JSON.stringify({
                isAuthenticated: true,
                isProfileSetupDone: false,
              }),
              7
            );
          } else {
            setCookie(
              "DHSAPPauth",
              JSON.stringify({
                isAuthenticated: true,
                isProfileSetupDone: true,
              }),
              7
            );
          }
        } else {
          const usersData = {
            email: "",
            password: "",
            rememberMe: "",
            token: response.data.token,
          };
          if (isProfileSetupDone === 0) {
            setCookie(
              "DHSAPPauth",
              JSON.stringify({
                isAuthenticated: true,
                isProfileSetupDone: false,
              }),
              7
            );
          } else {
            setCookie(
              "DHAPPauth",
              JSON.stringify({
                isAuthenticated: true,
                isProfileSetupDone: true,
              }),
              7
            );
          }
          setCookie("suser_data", JSON.stringify(usersData), 7);
        }
        if (isProfileSetupDone === 0) {
          navigate("/profile-setup");
        } else {
          navigate("/home");
        }
        //  navigate("/");
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  return (
    <>
      <div className="d-flex onboard">
        <div className="banner">
          <img src="assets/images/login-banner.svg" alt="" />
        </div>
        <div className="content">
          <h3>Haus Boss</h3>
          <h2>Change Password</h2>
          <p>Your new password must be different from previous.</p>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <div className="form-group">
              <label>
                Current Password<span className="text-danger"> *</span>
              </label>
              <input
                {...register("currentPassword", {
                  required: "Current password is required",
                  // minLength: {
                  //   value: 8,
                  //   message: "Password should be at least 8 characters long",
                  // },
                })}
                placeholder="Please enter current password"
                type={togglePasswordVisibility3 ? "" : "password"}
              />
              {errors?.currentPassword && (
                <p style={{ color: "red" }} className="error">
                  {errors.currentPassword.message}
                </p>
              )}
              {!togglePasswordVisibility3 ? (
                <img
                  onClick={() =>
                    setTogglePasswordVisibility3(!togglePasswordVisibility3)
                  }
                  src="assets/images/show.svg"
                  className="hide"
                  alt="showIcon"
                />
              ) : (
                <img
                  onClick={() =>
                    setTogglePasswordVisibility3(!togglePasswordVisibility3)
                  }
                  src="assets/images/hide.svg"
                  className="hide"
                  alt="hideIcon"
                />
              )}
            </div>
            <div className="form-group">
              <label>
                New Password<span className="text-danger"> *</span>
              </label>
              <input
                type={togglePasswordVisibility ? "" : "password"}
                name="password"
                placeholder="Please enter new password"
                {...register("password", {
                  required: "New password is required",
                  minLength: {
                    value: 8,
                    message: "Password should be at least 8 characters long",
                  },
                })}
              />

              {/* Validation error message */}
              {errors?.password && (
                <p style={{ color: "red" }} className="error">
                  {errors.password.message}
                </p>
              )}

              {!togglePasswordVisibility ? (
                <img
                  onClick={() =>
                    setTogglePasswordVisibility(!togglePasswordVisibility)
                  }
                  src="assets/images/show.svg"
                  className="hide"
                  alt="showIcon"
                />
              ) : (
                <img
                  onClick={() =>
                    setTogglePasswordVisibility(!togglePasswordVisibility)
                  }
                  src="assets/images/hide.svg"
                  className="hide"
                  alt="hideIcon"
                />
              )}
            </div>
            <div className="form-group">
              <label>
                Confirm Password<span className="text-danger"> *</span>
              </label>
              <input
                placeholder="Please enter confirm password"
                type={togglePasswordVisibility2 ? "" : "password"}
                name="confirmPassword"
                {...register("confirmPassword", {
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
              />

              {/* Validation error message */}
              {errors?.confirmPassword && (
                <p style={{ color: "red" }} className="error">
                  {errors.confirmPassword.message}
                </p>
              )}

              {!togglePasswordVisibility2 ? (
                <img
                  onClick={() =>
                    setTogglePasswordVisibility2(!togglePasswordVisibility2)
                  }
                  src="assets/images/show.svg"
                  className="hide"
                  alt="showIcon"
                />
              ) : (
                <img
                  onClick={() =>
                    setTogglePasswordVisibility2(!togglePasswordVisibility2)
                  }
                  src="assets/images/hide.svg"
                  className="hide"
                  alt="hideIcon"
                />
              )}
            </div>
            {/* <div className="form-group">
              <label>
                New Password<span className="text-danger"> *</span>
              </label>
              <input placeholder="Please enter new password" type="password" />
              <img
                src="assets/images/show.svg"
                className="hide"
                alt="showIcon"
              />
              <img
                src="assets/images/hide.svg"
                className="hide"
                alt="showIcon"
              />
            </div>
            <div className="form-group">
              <label>
                Confirm Password<span className="text-danger"> *</span>
              </label>
              <input
                placeholder="Please enter confirm password"
                type="password"
              />
              <img
                src="assets/images/show.svg"
                className="hide"
                alt="showIcon"
              />
              <img
                src="assets/images/hide.svg"
                className="hide"
                alt="showIcon"
              />
            </div> */}
            <div className="form-group">
              <button className="primery-btn">Change Password</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
