import "./App.scss";
import { createHashRouter, RouterProvider } from "react-router-dom";
import SignIn from "./pages/auth/SignIn";
import OnBoardLayout from "./component/layout/OnboardLayout";
import Dashboard from "./component/layout/Dashboard";
import Home from "./pages/homepage/home";
import ViewDeveloper from "./pages/viewDeveloper/ViewDeveloper";
import PropertyDashboard from "./pages/properties/propertyDashboard";
import ToastAlertContainer from "./component/Alert/ToastAlertContainer";
import AllProperties from "./pages/properties/AllProperties";
import ShowItemDetails from "./pages/properties/propertyDetails/ShowItemsDetails";
import Rooms from "./pages/rooms/Rooms";
import ChangePassword from "./pages/auth/ChangePassword";
import Advertisers from "./pages/advertisers/Advertisers";

function App() {
  const router = createHashRouter([
    {
      path: "/",
      element: <OnBoardLayout />,
      children: [
        {
          path: "",
          element: <SignIn />,
        },
        {
          path: "change-password",
          element: <ChangePassword />,
        },
      ],
    },
    {
      path: "/",
      element: <Dashboard />,
      children: [
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "developer/view/:id",
          element: <ViewDeveloper />,
        },
        {
          path: "properties/:id/user/:userid",
          element: <PropertyDashboard />,
        },
        {
          path: "all-properties/user/:id",
          element: <AllProperties />,
        },
        {
          path: "feature-item-details",
          element: <ShowItemDetails />,
        },
        {
          path: "rooms",
          element: <Rooms />,
        },
        {
          path: "advertisers",
          element: <Advertisers />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastAlertContainer />
    </div>
  );
}

export default App;









































