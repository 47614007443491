import React from "react";
import { useForm } from "react-hook-form";
import Axios from "../../axios/axiosInstance";
import useLoader from "../../component/loader/useLoader";
import { toastAlert } from "../../utils/toastAlert";
const CreateRoomForm = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { showLoader, hideLoader, Loader } = useLoader();

  const onSubmit = async (data) => {
    // Handle form submission here
    console.log(data);
    showLoader();
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/rooms `,
        {
          name: data?.roomName,
        }
      );
      if (response) {
        toastAlert("Room created successfully", "success");
        handleClose();
      }
    } catch (error) {
      if (error?.response?.data?.message.includes("Duplicate")) {
        toastAlert("Room created sent", "error");
      } else {
        toastAlert(error?.response?.data?.message, "error");
      }
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Loader />
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Room Name</label>
          <input
            maxLength={30}
            type="text"
            placeholder="type here"
            {...register("roomName", {
              required: "Room name is required",
              pattern: {
                value: /^(?=.*[a-zA-Z\d])[\w,\s-]+$/,
                message: "Invalid room name",
              },
              validate: (value) => value.trim().length > 0 || "Room name is required",
            })}
          />
          {errors.roomName && (
            <span role="alert" style={{ color: "red" }}>
              {errors.roomName.message}
            </span>
          )}
        </div>

        <div className="form-group">
          <button type="submit" className="primery-btn">
            Proceed
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateRoomForm;
