import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ModalComponent = ({ showModal, handleCloseModal, handler, body, heading, type }) => {
  return (
    <>
      <Modal centered show={showModal} onHide={handleCloseModal} className="delete-room-modal">
        {heading && (
          <Modal.Header>
            {type === "delete" && <img src="assets/images/delete.svg" style={{ width: "50px" }} />}
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{body && body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handler}>
            {type === "delete" ? "Delete" : "Approve"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalComponent;
