// import React from "react";
// import Accordion from "react-bootstrap/Accordion";

// const SupportStaffDetails = () => {
//   return <>
//     <Accordion
//         // defaultActiveKey="0"
//         className="property_accordian"
//       >
//         <Accordion.Item eventKey="0">
//           <Accordion.Header>
//             <div className="d-flex">
//               <span className="filltik">Builder </span>
//               <span className="edit">
//                 {" "}
//                 {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//               </span>
//             </div>
//           </Accordion.Header>
//           <Accordion.Body>
//             <div className="appliance">
//               <p>
//                 <span>Name</span>
//                 <b>Adex Builders</b>
//               </p>
//               <p>
//                 <span>Phone Number</span>
//                 <b>+1 866-233-8547</b>
//               </p>
//               <p>
//                 <span>Email</span>
//                 <b>adexbuild@gmail.com</b>
//               </p>
//             </div>
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="1">
//           <Accordion.Header>
//             <div className="d-flex">
//               <span className="filltik">Relationship Manager </span>
//               <span className="edit">
//                 {" "}
//                 {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//               </span>
//             </div>
//           </Accordion.Header>
//           <Accordion.Body>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//             ad ficia deserunt mollit anim id est laborum.
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="2">
//           <Accordion.Header>
//             <div className="d-flex">
//               <span className="filltik">24 Hour Help Line </span>
//               <span className="edit">
//                 {" "}
//                 {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//               </span>
//             </div>
//           </Accordion.Header>
//           <Accordion.Body>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//             ad ficia deserunt mollit anim id est laborum.
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="3">
//           <Accordion.Header>
//             <div className="d-flex">
//               <span className="filltik">Sales </span>
//               <span className="edit">
//                 {" "}
//                 {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//               </span>
//             </div>
//           </Accordion.Header>
//           <Accordion.Body>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//             ad ficia deserunt mollit anim id est laborum.
//           </Accordion.Body>
//         </Accordion.Item>
//         <Accordion.Item eventKey="4">
//           <Accordion.Header>
//             <div className="d-flex">
//               <span className="filltik">Mortgage </span>
//               <span className="edit">
//                 {" "}
//                 {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//               </span>
//             </div>
//           </Accordion.Header>
//           <Accordion.Body>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//             ad ficia deserunt mollit anim id est laborum.
//           </Accordion.Body>
//         </Accordion.Item>
//       </Accordion>
//   </>;
// };

// export default SupportStaffDetails;


import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import { Link } from "react-router-dom";
// import EditSupportStaff from "../forms/EditSupportStaff";

const SupportStaffDetails = ({
  ContractorsAndStaffData,
  propertyId,
  pageRefresh,
  setPageRefresh,
}) => {
  const [staffDetails, setStaffDetails] = useState([]);

  const [show, setShow] = useState(false);
  const [formView, setFormView] = useState("");
  const [contactId, setContactId] = useState("");

  // const handleClose = () => {
  //   setShow(false);
  // };

  // const handleShow = (form, id) => {
  //   console.log("idddddddddd", id);
  //   setContactId(id);
  //   setFormView(form);
  //   setShow(true);
  // };

  useEffect(() => {
    getStaffList();
  }, [ContractorsAndStaffData]);

  const getStaffList = () => {
    const filterStaffDetails = ContractorsAndStaffData?.filter((ele) => {
      return ele.type === "support";
    });
    setStaffDetails(filterStaffDetails);
  };

  // const handleFormView = () => {
  //   if (formView === "editContractors") {
  //     return (
  //       <>
  //         <Offcanvas.Header>
  //           <Offcanvas.Title>
  //             <h3>
  //               <Link onClick={handleClose} to="#">
  //                 <img src="assets/images/left-arrow.svg" alt="" />
  //               </Link>
  //               Edit Support Staff Details
  //             </h3>
  //           </Offcanvas.Title>
  //         </Offcanvas.Header>
  //         <Offcanvas.Body className="">
  //           <EditSupportStaff
  //             setPageRefresh={setPageRefresh}
  //             pageRefresh={pageRefresh}
  //             handleClose={handleClose}
  //             propertyId={propertyId}
  //             contactId={contactId}
  //           />
  //         </Offcanvas.Body>
  //       </>
  //     );
  //   }
  // };

  return (
    <>
      <Accordion
        // defaultActiveKey="0"
        className="property_accordian"
      >
        {staffDetails.length > 0 &&
          staffDetails?.map((ele, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>
                  <div className="d-flex">
                    <span className="filltik">
                      {" "}
                      {ele?.designation.charAt(0).toUpperCase() +
                        ele?.designation.slice(1).toLowerCase()}
                    </span>
                    <span className="edit">
                      {" "}
                      {/* <img
                        src="assets/images/edit.svg"
                        alt="edit icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShow("editContractors", ele?.id);
                        }}
                      /> */}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="appliance">
                    <p>
                      <span>Name</span>
                      <b>{ele?.contact_name}</b>
                    </p>
                    <p>
                      <span>Phone Number</span>
                      <b>{ele?.company_phonenumber}</b>
                    </p>
                    <p>
                      <span>Email</span>
                      <b>{ele?.company_email}</b>
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        {staffDetails.length === 0 && (
          <p className="not-fount">No data found</p>
        )}
      </Accordion>
      {/* <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        placement="end "
      >
        {handleFormView()}
      </Offcanvas> */}
    </>
  );
};

export default SupportStaffDetails;
