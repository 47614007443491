import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import PropertiesTable from "../properties/propertiesTable";
import Axios from "../../axios/axiosInstance";
import { toastAlert } from "../../utils/toastAlert";

const ViewDeveloper = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();
  const [stats, setStats] = useState();
  const [userData, setUserData] = useState();
  useEffect(() => {
    getPropertiesList();
  }, []);

  const getPropertiesList = () => {
    Axios.get(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/${id}/properties/stats`)
      .then((res) => {
        console.log("stats", res.data);
        setStats(res.data);
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {});
  };

  const [showAd, setShowAd] = useState(0);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/builders/${id}`)
      .then((res) => {
        setShowAd(res.data.user.show_advertisers);
        setUserData(res.data.user);
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {});
  }, []);

  const onClick = async (e) => {
    if (e.target.checked) {
      setShowAd(1);
    } else {
      setShowAd(0);
    }
    await Axios.patch(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/builders/${id}/advertisers`,
      {
        show_advertisers: e.target.checked,
      }
    )
      .then((res) => {
        console.log("stats", res.data);
        if (e.target.checked === true) {
          toastAlert("Advertisers activated successfully", "success");
        } else {
          toastAlert("Advertisers deactivated successfully", "success");
        }
        Axios.get(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/builders/${id}`)
          .then((res) => {
            setShowAd(res.data.user.show_advertisers);
          })
          .catch((err) => {
            toastAlert(err?.response?.data?.message, "error");
          })
          .finally((res) => {});
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {});
  };

  console.log("statusss", stats);
  return (
    <>
      <div className="developer-details">
        <div className="container">
          <div className="adv-rs d-flex">
            <Link to={"/home"} className="backlink">
              <img src="assets/images/left-arrow.svg" /> View Developer/ Agent
            </Link>
            <div className="rasts d-flex">
              <h3>Show Advertisers :</h3>
              <div class="slide-pin">
                <input checked={showAd === 1 ? true : false} onChange={onClick} type="checkbox" />
              </div>
            </div>
          </div>

          <div className="total-counter">
            <span className="name">
              <span>
                {userData?.logo ? (
                  <img src={`${process.env.REACT_APP_MEDIA_URL}${userData?.logo}`} alt="" />
                ) : (
                  <img src="assets/images/barry.svg" />
                )}
              </span>
              <div>
                {userData?.company_name}
                <p> {userData?.email}</p>
              </div>
            </span>
            <div className="property">
              <span className="n-p" style={{ backgroundColor: "#E4ECEB" }}>
                <p>Total Number of Properties</p>
                <b>{stats?.total}</b>
              </span>
              <span className="n-p" style={{ backgroundColor: "#FFF0CD" }}>
                <p>Total Allocated Properties</p>
                <b>{stats?.allocated}</b>
              </span>
              <span className="n-p" style={{ backgroundColor: "#FFF1E7" }}>
                <p>Total Unallocated Properties</p>
                <b>{stats?.unallocated}</b>
              </span>
            </div>
          </div>
          <div className="all-property">
            <div className="d-flex title">
              <h2>All Properties</h2>
              <input
                onClick={() =>
                  navigate("/all-properties/user/" + id, {
                    state: { isSearchClicked: true },
                  })
                }
                type="search"
                placeholder="Search properties by name or location"
              />
              <Link to={"/all-properties/user/" + id}>See All</Link>
              {/* <select>
                <option>Show all</option>
                <option>allocated</option>
                <option>unallocated</option>
              </select> */}
            </div>
            <div className="property-table">
              {/* <table>
                <thead>
                  <tr>
                    <th width="40%">Property Name</th>
                    <th width="48%">Property Address</th>
                    <th width="12%">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr onClick={() => navigate("/properties")}>
                    <td>Zurich A at Albany Village - Villas</td>
                    <td>14438 Shrawley Court, Carmel, IN 46074</td>
                    <td>
                      <Link to="/properties" className="alocated">
                        <img src="assets/images/located.svg" /> Allocated
                      </Link>
                    </td>
                  </tr>
                  <tr onClick={() => navigate("/properties")}>
                    <td>Zurich A at Albany Village - Villas</td>
                    <td>14438 Shrawley Court, Carmel, IN 46074</td>
                    <td>
                      <Link to="/properties" className="unalocated">
                        <img src="assets/images/unalocated.svg" /> Unallocated
                      </Link>
                    </td>
                  </tr>
                  <tr onClick={() => navigate("/properties")}>
                    <td>Zurich A at Albany Village - Villas</td>
                    <td>14438 Shrawley Court, Carmel, IN 46074</td>
                    <td>
                      <Link to="/properties" className="alocated">
                        <img src="assets/images/located.svg" /> Allocated
                      </Link>
                    </td>
                  </tr>
                  <tr onClick={() => navigate("/properties")}>
                    <td>Zurich A at Albany Village - Villas</td>
                    <td>14438 Shrawley Court, Carmel, IN 46074</td>
                    <td>
                      <Link to="/properties" className="unalocated">
                        <img src="assets/images/unalocated.svg" /> Unallocated
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <PropertiesTable
                userId={id}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                search={search}
                allList={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDeveloper;
