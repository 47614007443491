import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Axios from "../../axios/axiosInstance";
import CustomPagination from "../../component/pagination/Pagination";
import useLoader from "../../component/loader/useLoader";
import { toastAlert } from "../../utils/toastAlert";

const PropertiesTable = ({
  companyName,
  logo,
  userId,
  allList,
  search,
  homePageRefresh,
  setCurrentPage,
  currentPage,
  allocated,
}) => {
  const navigate = useNavigate();
  const [propertiesList, setPropertiesList] = useState([]);
  const { showLoader, hideLoader, Loader } = useLoader();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (isInitialLoad) {
      getPropertiesList();
      setIsInitialLoad(false);
    }
  }, [isInitialLoad]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Call your search function here

      getPropertiesList();
    }, 500); // Delay time in milliseconds

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search, allocated]);

  useEffect(() => {
    if (!isInitialLoad) {
      getPropertiesList();
    }
  }, [currentPage, homePageRefresh]);

  const getPropertiesList = () => {
    showLoader();
    Axios.get(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/${userId}/properties?page=${
        currentPage ? currentPage : 1
      }&q=${search ? search : ""}&allocated=${allocated ? allocated : ""}`
    )
      .then((res) => {
        setPropertiesList(res?.data?.properties);
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  return (
    <div className="responsive-table">
      <Loader />
      <table>
        <thead>
          <tr>
            <th width="40%">Property Name</th>
            <th width="40%">Property Address</th>
            <th width="20%"> Status</th>
          </tr>
        </thead>
        <tbody>
          {allList && propertiesList?.data?.length > 0
            ? propertiesList?.data?.map((ele, index) => {
                return (
                  <tr
                    onClick={() => {
                      navigate(`/properties/${ele.id}/user/${userId}`, {
                        state: {
                          devId: userId,
                          page: currentPage,
                          search: search,
                          allocated: allocated,
                          isAll: true,
                          companyName,
                          logo,
                        },
                      });
                    }}
                  >
                    <td>{ele?.name ? ele?.name : <span style={{ opacity: "0.4" }}>N/A</span>}</td>
                    <td>
                      {ele?.address}
                      {ele?.zipcode ? `, ${ele?.zipcode}` : ""}
                    </td>
                    {ele.allocated !== 0 ? (
                      <td>
                        <Link className="alocated">
                          <img src="assets/images/Wavy_Check.svg" alt="" /> Allocated
                        </Link>
                      </td>
                    ) : (
                      <td>
                        <Link to="#" className="unalocated">
                          <img src="assets/images/unalocated.svg" alt="" /> Unallocated
                        </Link>
                      </td>
                    )}
                  </tr>
                );
              })
            : propertiesList?.data
                ?.filter((item, index) => {
                  if (index < 5) {
                    return item;
                  }
                })
                .map((ele, i) => {
                  return (
                    <tr
                      onClick={() => {
                        navigate(`/properties/${ele.id}/user/${userId}`, {
                          state: {
                            devId: userId,
                            page: currentPage,
                            search: search,
                            allocated: allocated,
                            isAll: false,
                            companyName,
                            logo,
                          },
                        });
                      }}
                    >
                      <td>
                        <div className="property-table-name">
                          {ele?.name ? ele?.name : <span style={{ opacity: "0.4" }}>N/A</span>}
                        </div>
                      </td>
                      <td>
                        <div className="property-table-name">{ele?.address}</div>
                      </td>
                      {ele.allocated !== 0 ? (
                        <td>
                          <Link className="alocated">
                            <img src="assets/images/Wavy_Check.svg" alt="" /> Allocated
                          </Link>
                        </td>
                      ) : (
                        <td>
                          <Link to="#" className="unalocated">
                            <img src="assets/images/unalocated.svg" alt="" /> Unallocated
                          </Link>
                        </td>
                      )}
                    </tr>
                  );
                })}
        </tbody>
      </table>
      {propertiesList?.data?.length === 0 && <p className="not-fount">No data found</p>}
      {allList && propertiesList?.data?.length > 0 && (
        <CustomPagination
          currentPage={currentPage}
          totalPages={propertiesList?.last_page}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default PropertiesTable;
