import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import CreateAdvertisers from "./CreateAdvertisers";
import ModalComponent from "../../component/modal/modal";
import Axios from "../../axios/axiosInstance";
import CustomPagination from "../../component/pagination/Pagination";
import EditAdvertisers from "./EditAdvertisers";
import { toastAlert } from "../../utils/toastAlert";
import useLoader from "../../component/loader/useLoader";
import useDesignations from "../../hooks/useDesignations";
import { useForm, Controller } from "react-hook-form";

const Ads = ({ handleShow, pageRefresh, setPageRefresh, findTitleBySlug }) => {
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { showLoader, hideLoader, Loader } = useLoader();
  const [show, setShow] = useState(false);
  const [formView, setFormView] = useState("");
  const [roomData, setRoomData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [adData, setAdData] = useState();
  // const [pageRefresh, setPageRefresh] = useState(false);
  const [zipcode, setZipcode] = useState("");
  // const [cType, setCtype] = useState("")
  const [status, setStats] = useState("");
  const [adId, setAdId] = useState("");
  const { isLoading, error, designationData, fetchDesignations } = useDesignations();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleClose = () => setShow(false);

  useEffect(() => {
    fetchDesignations();
    getAdvertisers();
    handleClose();
  }, [pageRefresh, currentPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1);
      getAdvertisers();
    }, 500); // Delay time in milliseconds

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [zipcode, watch("cType"), watch("status")]);

  const handleFormView = () => {
    if (formView === "create") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                Create Advertisers
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CreateAdvertisers
              designationData={designationData}
              pageRefresh={pageRefresh}
              setPageRefresh={setPageRefresh}
            />
          </Offcanvas.Body>
        </>
      );
    } else if (formView === "edit") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                Edit Advertisers
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditAdvertisers
              designationData={designationData}
              adId={adId}
              pageRefresh={pageRefresh}
              setPageRefresh={setPageRefresh}
            />
          </Offcanvas.Body>
        </>
      );
    }
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (roomId) => {
    setShowModal(true);
  };
  const [selectedAds, setSelectedAds] = useState([]);

  const getAdvertisers = () => {
    showLoader();
    Axios.get(
      `${
        process.env.REACT_APP_DREES_HOME_LOCAL_URL
      }admin/advertisers/ads?page=${currentPage}&contractor_type=${watch(
        "cType"
      )}&zipcode=${zipcode}&status=${watch("status")}`
    )
      .then((res) => {
        console.log("Rresss", res.data.ads);

        setAdData(res.data.ads);
        // console.log("apiiiiiiii",res.data.data)
        const activeAds = res.data.ads.data
          .filter((ele) => ele.status === "active")
          .map((ele) => ele.id);

        setSelectedAds(activeAds);

        // toastAlert("Room deleted successfully.", "success");
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  const handleDeleteAdvertiser = () => {
    showLoader();
    Axios.delete(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/adcontacts/${adId}`)
      .then((res) => {
        getAdvertisers();
        handleCloseModal();
        toastAlert("Advertiser deleted successfully.", "success");
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  const handleCheckboxChange = async (adId, checked) => {
    setSelectedAds((prevSelectedAds) => {
      if (prevSelectedAds.includes(adId)) {
        return prevSelectedAds.filter((id) => id !== adId);
      } else {
        return [...prevSelectedAds, adId];
      }
    });
    showLoader();
    await Axios.patch(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/advertisers/ads/${adId}`,
      {
        status: checked,
      }
    )
      .then((res) => {
        getAdvertisers();

        if (checked === "active") {
          toastAlert("Ads activated successfully", "success");
        } else {
          toastAlert("Ads deactivated successfully", "success");
        }
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  return (
    <>
      <Loader />
      <div className="advertise">
        <div className="container">
          <div class="upload-title d-flex gap-3">
            <h4 className="m-0"> Advertisers</h4>

            <div className="active-plan">
              <input
                value={zipcode}
                onChange={(e) => {
                  setCurrentPage(1);
                  setZipcode(e.target.value);
                }}
                type="text"
                placeholder="Enter Zip Code "
              />
            </div>
            <div className="active-plan">
              <select
                {...register("cType", {
                  required: "Professional designation is required",
                  validate: (value) =>
                    value.trim().length > 0 || "Professional designation is required",
                })}
                placeholder="Contractor Type"
              >
                <option value={""} selected disabled>
                  Select contractor type
                </option>
                <option value={""}>Select all</option>
                {designationData?.map((ele) => {
                  return (
                    <optgroup label={ele.label}>
                      {ele.options.map((e) => {
                        return <option value={e?.slug}>{e?.title}</option>;
                      })}
                    </optgroup>
                  );
                })}
              </select>
            </div>
            <div className="active-plan">
              <select placeholder="ssssss" {...register("status")}>
                <option value={""} disabled selected>
                  Select status...
                </option>
                <option value={""}>Select all</option>
                <option value={"active"}>Active</option>
                <option value={"inActive"}>Inactive</option>
              </select>
            </div>
            <div class="all-list-show">
              <button onClick={() => handleShow("create")} className="primery-btn">
                <img src="assets/images/plus2.svg" /> Create Advertiser
              </button>
            </div>
          </div>
          <div class="property-table">
            <div class="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th width="10%">Zip Code</th>
                    <th width="25%">Contractor Type</th>
                    <th width="10%"> Ad1</th>
                    <th width="10%">Ad2</th>
                    <th width="10%">Ad3</th>
                    <th width="25%"> Banner</th>
                    <th width="10%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adData?.data?.length > 0 &&
                    adData?.data?.map((ele) => {
                      return (
                        <tr>
                          <td>{ele?.zipcode}</td>
                          <td>{findTitleBySlug(ele?.contractor_type)}</td>
                          <td>
                            {" "}
                            {ele.ads && ele.ads.length > 0 ? (
                              ele.ads[0]?.logo ? (
                                <img
                                  className="t-l"
                                  src={`${process.env.REACT_APP_MEDIA_URL}${ele?.ads[0]?.logo}`}
                                  alt=""
                                />
                              ) : (
                                <img className="t-l" src="assets/images/logo-not-uploaded.svg" />
                              )
                            ) : (
                              <div className="empty-l">
                                <img className="w-100 h-100" src="assets/images/A.svg" />
                              </div>
                            )}
                          </td>
                          <td>
                            {" "}
                            {ele.ads && ele.ads.length > 1 ? (
                              ele.ads[1]?.logo ? (
                                <img
                                  className="t-l"
                                  src={`${process.env.REACT_APP_MEDIA_URL}${ele?.ads[1]?.logo}`}
                                  alt=""
                                />
                              ) : (
                                <img className="t-l" src="assets/images/logo-not-uploaded.svg" />
                              )
                            ) : (
                              <div className="empty-l">
                                <img className="w-100 h-100" src="assets/images/A.svg" />
                              </div>
                            )}
                          </td>
                          <td>
                            {" "}
                            {ele.ads && ele.ads.length > 2 ? (
                              ele.ads[2]?.logo ? (
                                <img
                                  className="t-l"
                                  src={`${process.env.REACT_APP_MEDIA_URL}${ele?.ads[2]?.logo}`}
                                  alt=""
                                />
                              ) : (
                                <img className="t-l" src="assets/images/logo-not-uploaded.svg" />
                              )
                            ) : (
                              <div className="empty-l">
                                <img className="w-100 h-100" src="assets/images/A.svg" />
                              </div>
                            )}
                          </td>
                          <td>
                            {" "}
                            {ele.banners && ele.banners.length > 0 ? (
                              ele.banners[0]?.banner ? (
                                <img
                                  className="t-banner"
                                  src={`${process.env.REACT_APP_MEDIA_URL}${ele?.banners[0]?.banner}`}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="t-banner"
                                  src="assets/images/banner-not-uploaded.svg"
                                />
                              )
                            ) : (
                              <div className="empty-b">
                                {" "}
                                <img className="t-banner" src="assets/images/A.svg" />
                              </div>
                            )}
                          </td>
                          <td>
                            <div className="d-flex ">
                              {/* <a
                                onClick={() => {
                                  handleShow("AdsEdit");
                                  // setAdId(ele.id);
                                }}
                                class="deleteicon"
                              >
                                <img src="assets/images/edit.svg" alt="" />
                              </a> */}
                              <div class="slide-pin">
                                <input
                                  checked={selectedAds.includes(ele.id) ? true : false}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      ele.id,
                                      e.target.checked === true ? "active" : "inactive"
                                    );
                                  }}
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {adData?.data?.length === 0 && <p className="not-fount mt-4">No data found</p>}
              <CustomPagination
                currentPage={currentPage}
                totalPages={adData?.last_page}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} backdrop={true} placement="end">
        {handleFormView()}
      </Offcanvas>
      <ModalComponent
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handler={handleDeleteAdvertiser}
        heading={"Delete advertiser"}
        body={"Are you sure you want to delete this advertiser?"}
      />
    </>
  );
};

export default Ads;
