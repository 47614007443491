import React from "react";
import { useForm } from "react-hook-form";
import Axios from "../../axios/axiosInstance";
import useLoader from "../../component/loader/useLoader";
import { toastAlert } from "../../utils/toastAlert";
const InviteAgentBuilderForm = ({ handleClose, setPageRefresh, pageRefresh, setCurrentPage }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { showLoader, hideLoader, Loader } = useLoader();

  const onSubmit = async (data) => {
    // Handle form submission here
    console.log(data);
    showLoader();
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/builders/invite `,
        {
          email: data?.companyEmail,
          company_name: data?.companyName,
          contact_name: data?.contactName,
        }
      );
      if (response) {
        toastAlert("Invitation sent successfully", "success");
        setPageRefresh(!pageRefresh);
        setCurrentPage(1)
        handleClose();
      }
    } catch (error) {
      if (error?.response?.data?.message.includes("Duplicate")) {
        toastAlert("Invitation already sent", "error");
      } else {
        toastAlert(error?.response?.data?.message, "error");
      }
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Loader />
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Company Name</label>
          <input
            maxLength={30}
            type="text"
            placeholder="Company name"
            {...register("companyName", {
              required: "Company name is required",
              validate: (value) => value.trim().length > 0 || "Company name is required",
            })}
          />
          {errors.companyName && (
            <span role="alert" style={{ color: "red" }}>
              {errors.companyName.message}
            </span>
          )}
        </div>
        <div className="form-group">
          <label>Company Email</label>
          <input
            type="email"
            placeholder="Company email"
            {...register("companyEmail", {
              required: "Company email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address",
              },
            })}
          />
          {errors.companyEmail && (
            <span role="alert" style={{ color: "red" }}>
              {errors.companyEmail.message}
            </span>
          )}
        </div>
        <div className="form-group">
          <label>Contact Name</label>
          <input
            maxLength={30}
            type="text"
            placeholder="Contact name"
            {...register("contactName", {
              required: "Contact name is required",
              validate: (value) => value.trim().length > 0 || "Contact name is required",
            })}
          />
          {errors.contactName && (
            <span role="alert" style={{ color: "red" }}>
              {errors.contactName.message}
            </span>
          )}
        </div>
        <div className="form-group">
          <button type="submit" className="primery-btn">
            Send Invite
          </button>
        </div>
      </form>
    </>
  );
};

export default InviteAgentBuilderForm;
