import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import CreateAdvertisers from "./CreateAdvertisers";
import ModalComponent from "../../component/modal/modal";
import Axios from "../../axios/axiosInstance";
import CustomPagination from "../../component/pagination/Pagination";
import EditAdvertisers from "./EditAdvertisers";
import { toastAlert } from "../../utils/toastAlert";
import useLoader from "../../component/loader/useLoader";
import { formatDate } from "../../utils/dateConverter";

const Approved = ({ handleShow, pageRefresh, setPageRefresh, adId, setAdId, findTitleBySlug }) => {
  const { showLoader, hideLoader, Loader } = useLoader();

  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [adData, setAdData] = useState();
  const [search, setSearch] = useState("");
  const [typeofModal, setTypeOfModal] = useState("");
  const [plan, setPlan] = useState("");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAdvertisers();
  }, [pageRefresh, currentPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAdvertisers();
    }, 500); // Delay time in milliseconds

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search, plan]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (type) => {
    setTypeOfModal(type);
    setShowModal(true);
  };

  const getAdvertisers = () => {
    showLoader();
    Axios.get(
      `${
        process.env.REACT_APP_DREES_HOME_LOCAL_URL
      }admin/advertisers?approved=1&subscription_status=${
        plan ? plan : ""
      }&page=${currentPage}&q=${search}`
    )
      .then((res) => {
        console.log("Rresss", res.data.users);
        setAdData(res.data.users);
        const activeAds = res.data.users.data
          .filter((ele) => ele.approved === 1)
          .map((ele) => ele.id);

        setSelectedAds(activeAds);
        // toastAlert("Room deleted successfully.", "success");
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  const handleDeleteAdvertiser = () => {
    showLoader();
    Axios.delete(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/advertisers/${adId}`)
      .then((res) => {
        getAdvertisers();
        handleCloseModal();
        toastAlert("Advertiser deleted successfully.", "success");
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        setPageRefresh(!pageRefresh);
        hideLoader();
      });
  };
  const [selectedAds, setSelectedAds] = useState([]);
  const handleCheckboxChange = async (adId, checked) => {
    setSelectedAds((prevSelectedAds) => {
      if (prevSelectedAds.includes(adId)) {
        return prevSelectedAds.filter((id) => id !== adId);
      } else {
        return [...prevSelectedAds, adId];
      }
    });
    showLoader();
    await Axios.patch(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/advertisers/${adId}/approve`,
      {
        approved: checked ? 1 : 0,
      }
    )
      .then((res) => {
        setTimeout(() => {
          getAdvertisers();
        }, 500);

        if (checked === "active") {
          toastAlert("Advertiser approved successfully", "success");
        } else {
          toastAlert("Advertiser rejected successfully", "success");
        }
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        setTimeout(() => {
          hideLoader();
        }, 200);
      });
  };

  return (
    <>
      <Loader />
      <div className="advertise">
        <div className="container">
          <div class="upload-title d-flex">
            <h4 className="m-0"> Advertisers</h4>
            <div class="search-video" style={{ minWidth: "400px" }}>
              <input
                value={search}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearch(e.target.value);
                }}
                class="form-control border rounded-pill search_icon"
                type="text"
                id="example-search-input"
                placeholder="Search advertiser by company name"
              />
            </div>
            <div className="active-plan">
              <select
                onChange={(e) => {
                  setPlan(e.target.value);
                }}
              >
                <option disabled selected>
                  Select plan
                </option>
                <option value={""}>Select all</option>
                <option value={"active"}>Active Plan</option>
                <option value={"expired"}>Expired Plan</option>
              </select>
            </div>
            <div class="all-list-show">
              <button onClick={() => handleShow("create")} className="primery-btn">
                <img src="assets/images/plus2.svg" /> Create Advertiser
              </button>
            </div>
          </div>
          <div class="property-table">
            <div class="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th width="6%">Logo</th>
                    <th width="12%">Contractor Type</th>
                    <th width="12%"> Contact Name</th>
                    <th width="12%">Company Name</th>
                    <th width="10%">Created By</th>
                    <th width="9%"> Zipcode Count</th>
                    <th width="12%">Start Date</th>
                    <th width="12%"> End Date</th>
                    <th width="20%"> Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adData?.data?.length > 0 &&
                    adData?.data?.map((ele) => {
                      return (
                        <tr>
                          <td>
                            <img
                              className="adve-logo"
                              src={
                                ele?.logo
                                  ? `${process.env.REACT_APP_MEDIA_URL}${ele?.logo}`
                                  : "assets/images/logo.svg"
                              }
                              alt={ele?.logo}
                            />
                          </td>
                          <td>{findTitleBySlug(ele?.contractor_type)}</td>
                          <td>{ele?.contact_name}</td>
                          <td>{ele?.company_name}</td>
                          <td>
                            {ele?.created_by === "sadmin"
                              ? "Super Admin"
                              : ele.created_by === "contractor"
                              ? "Contractor"
                              : "N/A"}
                          </td>
                          <td>{ele?.zipcodes_count}</td>

                          <td>
                            {ele?.subscription_start ? formatDate(ele?.subscription_start) : "N/A"}
                          </td>
                          <td>
                            {ele?.subscription_end ? formatDate(ele?.subscription_end) : "N/A"}
                          </td>

                          <td className="d-flex" style={{ cursor: "pointer" }}>
                            <a
                              className="deleteicon"
                              onClick={() => {
                                handleShow("viewDetaills");
                                setAdId(ele.id);
                              }}
                            >
                              <img src="assets/images/hide.svg" alt="" />
                            </a>
                            <a
                              onClick={() => {
                                handleShow("edit");
                                setAdId(ele.id);
                              }}
                              className="deleteicon"
                            >
                              <img src="assets/images/edit.svg" alt="" />
                            </a>
                            <a
                              onClick={() => {
                                setAdId(ele.id);
                                handleShowModal("delete");
                              }}
                              className="deleteicon"
                            >
                              <img src="assets/images/delete.svg" alt="" />
                            </a>
                            <div class="slide-pin">
                              <input
                                checked={selectedAds.includes(ele.id) ? true : false}
                                onChange={(e) => {
                                  handleCheckboxChange(ele.id, e.target.checked === true ? 1 : 0);
                                }}
                                type="checkbox"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {adData?.data?.length === 0 && <p className="not-fount mt-4">No data found</p>}
              <CustomPagination
                currentPage={currentPage}
                totalPages={adData?.last_page}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        type={typeofModal}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handler={handleDeleteAdvertiser}
        heading={"Delete advertiser"}
        body={"Are you sure you want to delete this advertiser?"}
      />
    </>
  );
};

export default Approved;
