import { useState } from "react";
import Axios from "../axios/axiosInstance";

const useDesignations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [designationData, setDesignationData] = useState(null);

  const fetchDesignations = async (propertyId, contactId) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}generics/designations `
      );

      const data = response?.data;
      setDesignationData(data);
    } catch (err) {
      setError(err?.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    designationData,
    fetchDesignations,
  };
};

export default useDesignations;
