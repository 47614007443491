import React, { useState, useEffect, lazy, useRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Player } from "video-react";
import ReactAudioPlayer from "react-audio-player";

const ShowItemDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [itemList, setItemList] = useState([]);

  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [audioElements, setAudioElements] = useState([]);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const audioRef = useRef(null);
  console.log("items details", location.state);
  useEffect(() => {
    getItemList();
  }, []);

  useEffect(() => {
    // Add event listener for keyboard arrow keys
    const handleKeyPress = (event) => {
      if (showModal && itemList.length > 0) {
        if (event.key === "ArrowLeft") {
          openModal(selectedItemIndex === 0 ? itemList.length - 1 : selectedItemIndex - 1);
        } else if (event.key === "ArrowRight") {
          openModal(selectedItemIndex === itemList.length - 1 ? 0 : selectedItemIndex + 1);
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [showModal, selectedItemIndex]);

  const getItemList = () => {
    setItemList(location?.state);
  };

  const openModal = (index) => {
    if (audioRef.current) {
      audioRef.current.pause();
      setCurrentPlayingIndex(null);
    }
    setSelectedItemIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getItemList();

    // Cleanup function to pause audio when the component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  // ...

  const playAudio = (index) => {
    if (currentPlayingIndex === index) {
      // If the clicked audio is already playing, pause it
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setCurrentPlayingIndex(null);
    } else {
      // If a different audio is clicked, stop the current one and play the new one
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(`${process.env.REACT_APP_MEDIA_URL}${itemList[index]?.source}`);
      audioRef.current.play();
      setCurrentPlayingIndex(index);
    }
  };

  return (
    <>
      <div className="video-library">
        <div className="container">
          <div className="upload-title d-flex">
            <div className="backlink">
              <Link onClick={() => navigate(-1)}>
                <img src="assets/images/left-arrow.svg" alt="back" /> Media Files
              </Link>
            </div>
            <div className="search-video"></div>
          </div>

          {itemList?.length > 0 ? (
            <div className="video">
              {itemList?.map((ele, i) => (
                <div className="content" key={i}>
                  <div className="vide-box">
                    <div onClick={() => openModal(i)} className="videoshow">
                      {ele?.source.endsWith(".mp4") ? (
                        <>
                          <video autoPlay muted>
                            <source
                              width="100%"
                              height="100%"
                              src={`${process.env.REACT_APP_MEDIA_URL}${ele?.source}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                          <img src="assets/images/playicon.svg" className="playicon" />
                        </>
                      ) : ele?.source.endsWith(".mp3") ? (
                        <div>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              playAudio(i);
                            }}
                          >
                            {currentPlayingIndex === i ? (
                              <img
                                src="assets/images/push.svg"
                                className="playicon"
                                alt="video-play-icon"
                              />
                            ) : (
                              <img
                                src="assets/images/music.svg"
                                className="playicon"
                                alt="video-play-icon"
                              />
                            )}
                          </span>
                        </div>
                      ) : (
                        <img
                          onLoad={lazy}
                          src={`${process.env.REACT_APP_MEDIA_URL}${ele?.source}`}
                          alt="video-thumbnail"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="not-fount">No data found</p>
          )}
        </div>
      </div>
      <Modal
        className="fullscreen-modal"
        // className="delete-modal"
        show={showModal}
        onHide={closeModal}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          {selectedItemIndex !== null && (
            <div className="fullscreen-image-container">
              {itemList[selectedItemIndex]?.source.endsWith(".mp4") ? (
                <Player
                  autoPlay={true}
                  preload={"auto"}
                  playsInline={true}
                  poster="/assets/poster.png"
                  src={`${process.env.REACT_APP_MEDIA_URL}${itemList[selectedItemIndex]?.source}`}
                />
              ) : itemList[selectedItemIndex]?.source.endsWith(".mp3") ? (
                // <audio controls>
                //   <source
                //     src={`${process.env.REACT_APP_MEDIA_URL}${itemList[selectedItemIndex]?.source}`}
                //     type="audio/mpeg"
                //   />
                //   Your browser does not support the audio tag.
                //   </audio>
                // <audio controls src={`${process.env.REACT_APP_MEDIA_URL}${itemList[selectedItemIndex]?.source}`}></audio>
                <ReactAudioPlayer
                  onLoad={lazy}
                  loop={true}
                  preload="auto"
                  src={`${process.env.REACT_APP_MEDIA_URL}${itemList[selectedItemIndex]?.source}`}
                  //autoPlay={isAudioPlaying && selectedItemIndex === index}
                  controls={true}
                />
              ) : (
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}${itemList[selectedItemIndex]?.source}`}
                  alt="video-thumbnail"
                />
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              openModal(selectedItemIndex === 0 ? itemList.length - 1 : selectedItemIndex - 1)
            }
          >
            {"<"}
          </Button>
          <Button
            onClick={() =>
              openModal(selectedItemIndex === itemList.length - 1 ? 0 : selectedItemIndex + 1)
            }
          >
            {">"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShowItemDetails;
