import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import FeatureDetails from "./FeatureDetails";

const RoomsAndFeatures = ({ roomList, propertyId, userid }) => {
  const [show, setShow] = useState(false);
  const [formView, setFormView] = useState("");
  const [roomId, setRoomId] = useState("");
  const [featureId, setFeatureId] = useState("");

  const handleClose = () => setShow(false);

  const handleShow = (form, rId, fId) => {
    if (rId) {
      setRoomId(rId);
    }
    if (fId) {
      setFeatureId(fId);
    }
    setFormView(form);
    setShow(true);
  };

  const handleFormView = () => {
    if (formView === "featureDetails") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                {/* Feature details */}
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            <FeatureDetails
              userid={userid}
              propertyId={propertyId}
              roomId={roomId}
              featureId={featureId}
            />
          </Offcanvas.Body>
        </>
      );
    }
  };

  return (
    <>
      <Accordion
        // defaultActiveKey="0"
        className="property_accordian"
      >
        {roomList?.length > 0 &&
          roomList?.map((ele) => {
            return (
              <Accordion.Item eventKey={ele?.id}>
                <Accordion.Header>
                  <div className="d-flex">
                    <span className="filltik">
                      {ele?.name} <span>{ele?.features_count}</span>
                    </span>
                    <span className="edit">
                      {" "}
                      {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {ele?.features?.length > 0 && <h4>Features</h4>}
                  <div className="property-type">
                    {ele.features.map((feature) => {
                      return (
                        <div
                          onClick={() =>
                            handleShow("featureDetails", ele?.id, feature?.id)
                          }
                          className="box"
                          style={{ backgroundColor: feature?.color }}
                        >
                          {/* <img
                            src={`${process.env.REACT_APP_MEDIA_URL}${feature?.icon}`}
                            alt=""
                          /> */}
                          <span>
                            {" "}
                            {feature?.name.length > 15
                              ? `${feature?.name?.substring(0, 15)} ...`
                              : feature?.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  {ele?.features?.length === 0 && (
                    <div>
                      <p style={{ fontSize: "16px" }} className="not-fount">
                        No data found
                      </p>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        {roomList?.length === 0 && <p className="not-fount">No data found</p>}
      </Accordion>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        placement="end "
      >
        {handleFormView()}
      </Offcanvas>
    </>
  );
};

export default RoomsAndFeatures;
