import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../component/loader/useLoader";
import Axios from "../../axios/axiosInstance";
import { toastAlert } from "../../utils/toastAlert";
import CustomPagination from "../../component/pagination/Pagination";
const DeveloperList = ({ search, currentPage, setCurrentPage, pageRefresh }) => {
  const navigate = useNavigate();
  const { showLoader, hideLoader, Loader } = useLoader();

  const [usersData, setUsersData] = useState();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Call your search function here

      getPropertiesList();
    }, 500); // Delay time in milliseconds

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  useEffect(() => {
    getPropertiesList();
  }, [currentPage, pageRefresh]);


  const listInnerRef = useRef();

  // const onScroll = () => {
  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //     if (scrollTop + clientHeight === scrollHeight) {
  //       setCurrentPage(currentPage + 1);
  //     }
  //   }
  // };

  const getPropertiesList = () => {
    showLoader();
    Axios.get(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/builders?page=${currentPage}&q=${
        search ? search : ""
      }`
    )
      .then((res) => {
        // if (currentPage > 1) {
        //   // If current page is greater than 1, append the new data to the existing usersList
        //   setUserList((prevUsersList) => [
        //     ...prevUsersList,
        //     ...res.data.users.data,
        //   ]);
        // } else {
        // If current page is 1, set the usersList with the new data
        setUserList(res.data.users.data);
        setUsersData(res.data.users);
        // }
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };
  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  function capitalizeFirstLetterAndReplaceUnderscoresWithSpaces(inputString) {
    // Replace underscores with spaces
    let stringWithSpaces = inputString.replace(/_/g, " ");

    // Convert the entire string to lowercase
    let result = stringWithSpaces.toLowerCase();

    // Capitalize the first letter of the string
    result = result.charAt(0).toUpperCase() + result.slice(1);

    return result;
  }

  return (
    <>
      <Loader />
      {/* <div onScroll={onScroll} ref={listInnerRef} className="developer-list">
        {userList?.length >0 &&
          userList?.map((ele) => {
            return (
              <Link
                state={{ companyName: ele?.company_name, logo: ele?.logo,companyEmail:ele?.email }}
                to={"/developer/view/" + ele?.id}
                className="d-flex align-items-center"
              >
                <div className="dev-pic">
                  {ele.logo ? (
                    <img
                      src={`${process.env.REACT_APP_MEDIA_URL}${ele?.logo}`}
                      alt=""
                    />
                  ) : (
                    <img src="assets/images/barry.svg" />
                  )}
                </div>
                <div className="dev-name">
                  <h4>{ele?.company_name}</h4>
                  <p>
                    {ele?.property_count}{" "}
                    {ele?.property_count > 1 ? "properties" : "property"}
                  </p>
                </div>
              </Link>
            );
          })}
        {userList?.length === 0 && (
          <p  className="not-fount">
            No data found
          </p>
        )}
      </div> */}
      <div class="property-table">
        <div class="responsive-table">
          <table>
            <thead>
              <tr>
                <th width="10%">Logo</th>
                <th width="18%">Company Name</th>
                <th width="15%"> Contact Name</th>
                <th width="17%">Role</th>
                <th width="15%">No. of Properties</th>
                <th width="22%"> Email</th>
                {/* <th width="10%">Active</th> */}
              </tr>
            </thead>
            <tbody>
              {userList?.length > 0 &&
                userList?.map((ele) => {
                  return (
                    <tr onClick={() => navigate("/developer/view/" + ele?.id)}>
                      <td>
                        {ele.logo ? (
                          <img
                            className="t-l"
                            src={`${process.env.REACT_APP_MEDIA_URL}${ele?.logo}`}
                            alt=""
                          />
                        ) : (
                          <img className="t-l" src="assets/images/barry.svg" />
                        )}
                      </td>
                      <td>
                        {ele?.company_name ? (
                          ele?.company_name
                        ) : (
                          <span style={{ opacity: "0.4" }}>N/A</span>
                        )}
                      </td>
                      <td>
                        {ele?.contact_name ? (
                          ele?.contact_name
                        ) : (
                          <span style={{ opacity: "0.4" }}>N/A</span>
                        )}
                      </td>
                      <td>
                        {ele?.role ? (
                          ele?.role
                            ?.split(/[-_]/) // Split the string by hyphens or underscores
                            .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)) // Capitalize each word
                            ?.join(" ")
                        ) : (
                          <span style={{ opacity: "0.4" }}>N/A</span>
                        )}
                      </td>
                      <td>
                        {ele?.property_count ? (
                          ele?.property_count
                        ) : (
                          <span style={{ opacity: "0.4" }}>0</span>
                        )}
                      </td>
                      <td>
                        {ele?.email ? ele?.email : <span style={{ opacity: "0.4" }}>N/A</span>}
                      </td>
                      {/* <td>
                      <div class="slide-pin">
                        <input type="checkbox" />
                      </div>
                    </td> */}
                    </tr>
                  );
                })}
              {/* <tr>
                <td>
                  <img src="assets/images/t-l.svg" className="t-l" />
                </td>
                <td>Anytime Property</td>
                <td>Steve James</td>
                <td>Agent</td>
                <td>10</td>
                <td>anytime_pro@gmail.com</td>
                <td>
                  <div class="slide-pin">
                    <input type="checkbox" />
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
          {userList?.length === 0 && <p className="not-fount mt-4">No data found</p>}
          <CustomPagination
            currentPage={currentPage}
            totalPages={usersData?.last_page}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default DeveloperList;
