import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import CreateAdvertisers from "./CreateAdvertisers";
import ModalComponent from "../../component/modal/modal";
import Axios from "../../axios/axiosInstance";
import CustomPagination from "../../component/pagination/Pagination";
import EditAdvertisers from "./EditAdvertisers";
import { toastAlert } from "../../utils/toastAlert";
import useLoader from "../../component/loader/useLoader";
import useDesignations from "../../hooks/useDesignations";

const NotApproved = ({
  handleShow,
  pageRefresh,
  setPageRefresh,
  adId,
  setAdId,
  findTitleBySlug,
}) => {
  const { showLoader, hideLoader, Loader } = useLoader();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [adData, setAdData] = useState();
  const [typeofModal, setTypeOfModal] = useState("");
  const [search, setSearch] = useState("");

  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  useEffect(() => {
    getAdvertisers();
  }, [pageRefresh, currentPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAdvertisers();
    }, 500); // Delay time in milliseconds

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (type) => {
    setTypeOfModal(type);
    setShowModal(true);
  };

  const getAdvertisers = () => {
    showLoader();
    Axios.get(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/advertisers?approved=0&page=${currentPage}&q=${search}`
    )
      .then((res) => {
        console.log("Rresss", res.data.users);
        setAdData(res.data.users);
        // toastAlert("Room deleted successfully.", "success");
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  const handleDeleteAdvertiser = () => {
    showLoader();
    Axios.delete(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/advertisers/${adId}`)
      .then((res) => {
        getAdvertisers();
        handleCloseModal();
        toastAlert("Advertiser deleted successfully.", "success");
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        setPageRefresh(!pageRefresh);
        hideLoader();
      });
  };

  const onClickApprove = (id) => {
    showLoader();
    Axios.patch(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/advertisers/${adId}/approve`, {
      approved: 1,
    })
      .then((res) => {
        getAdvertisers();
        handleCloseModal();
        toastAlert("Advertiser approved successfully.", "success");
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        setPageRefresh(!pageRefresh);
        hideLoader();
      });
  };

  return (
    <>
      <Loader />
      <div className="advertise">
        <div className="container">
          <div class="upload-title d-flex">
            <h4 className="m-0"> Advertisers</h4>
            <div class="search-video" style={{ minWidth: "450px" }}>
              <input
                value={search}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearch(e.target.value);
                }}
                class="form-control border rounded-pill search_icon"
                type="text"
                id="example-search-input"
                placeholder="Search advertiser by company name"
              />
            </div>
            <div class="all-list-show">
              <button onClick={() => handleShow("create")} className="primery-btn">
                <img src="assets/images/plus2.svg" /> Create Advertiser
              </button>
            </div>
          </div>
          <div class="property-table">
            <div class="responsive-table">
              <table>
                <thead>
                  <tr>
                    <th width="6%">Logo</th>
                    <th width="15%">Contractor Type</th>
                    <th width="12%"> Contact Name</th>
                    <th width="14%">Company Name</th>
                    <th width="10%">Phone No.</th>
                    <th width="15%"> Email</th>
                    <th width="14%">Website</th>
                    {/* <th width="7%"> Zip Code</th> */}
                    <th width="15%"> Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adData?.data?.length > 0 &&
                    adData?.data?.map((ele) => {
                      return (
                        <tr>
                          <td>
                            <img
                              className="adve-logo"
                              src={
                                ele?.logo
                                  ? `${process.env.REACT_APP_MEDIA_URL}${ele?.logo}`
                                  : "assets/images/logo.svg"
                              }
                              alt={ele?.logo}
                            />

                            {/* <img alt="" src="assets/images/home.svg" className="adve-logo" /> */}
                          </td>
                          <td>{findTitleBySlug(ele?.contractor_type)}</td>
                          <td>{ele?.contact_name}</td>
                          <td>{ele?.company_name}</td>
                          <td>{ele?.phonenumber}</td>
                          <td>{ele?.company_email}</td>

                          <td>
                            <a
                              target="_blank"
                              style={{ color: "#2A9D4A", textDecoration: "none" }}
                              href={ele?.company_website}
                            >
                              {ele?.company_website?.split("/")[2] ? (
                                ele?.company_website?.split("/")[2]
                              ) : ele?.company_website ? (
                                ele.company_website
                              ) : (
                                <span style={{ color: "lightgrey" }}>N/A</span>
                              )}
                            </a>
                          </td>
                          {/* <td>{ele?.zipcode}</td> */}
                          <td>
                            <a
                              onClick={() => {
                                handleShow("edit");
                                setAdId(ele.id);
                              }}
                              class="deleteicon"
                            >
                              <img src="assets/images/edit.svg" alt="" />
                            </a>
                            <a
                              onClick={() => {
                                setAdId(ele.id);
                                handleShowModal("delete");
                              }}
                              class="deleteicon"
                            >
                              <img src="assets/images/delete.svg" alt="" />
                            </a>
                            <button
                              onClick={() => {
                                if (ele.subscription_status && ele.profile_progress === 3) {
                                  // onClickApprove(ele.id);
                                  setAdId(ele.id);
                                  handleShowModal("approve");
                                }
                              }}
                              disabled={
                                ele.subscription_status && ele.profile_progress === 3 ? false : true
                              }
                              className="btn btn-primary nt-btn"
                            >
                              Approve
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {adData?.data?.length === 0 && <p className="not-fount mt-4">No data found</p>}
              <CustomPagination
                currentPage={currentPage}
                totalPages={adData?.last_page}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        type={typeofModal}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handler={typeofModal === "delete" ? handleDeleteAdvertiser : onClickApprove}
        heading={typeofModal === "delete" ? "Delete advertiser" : "Approve advertiser"}
        body={`Are you sure you want to ${typeofModal} this advertiser?`}
      />
    </>
  );
};

export default NotApproved;
