import React, { useEffect, useState } from "react";
import CustomPagination from "../../component/pagination/Pagination";
import { toastAlert } from "../../utils/toastAlert";
import Axios from "../../axios/axiosInstance";
import { useSearchParams } from "react-router-dom";
import ModalComponent from "../../component/modal/modal";
import useLoader from "../../component/loader/useLoader";
const RoomsTable = ({
  search,
  currentPage,
  setCurrentPage,
  show,
  handleShow,
}) => {
  const { showLoader, hideLoader, Loader } = useLoader();
  const [roomData, setRoomData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [roomId, setRoomId] = useState("");

  useEffect(() => {
    getRoomsList();
  }, [show, showModal, currentPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Call your search function here

      getRoomsList();
    }, 500); // Delay time in milliseconds

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  const getRoomsList = () => {
    showLoader();
    Axios.get(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/rooms?page=${currentPage}&q=${search}`
    )
      .then((res) => {
        setRoomData(res.data.rooms);
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (roomId) => {
    if (roomId) {
      setRoomId(roomId);
    }
    setShowModal(true);
  };

  const handleDeleteRoom= () => {
    Axios.delete(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/rooms/${roomId}`
    )
      .then((res) => {
        handleCloseModal();
        toastAlert("Room deleted successfully.", "success");
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        // hideLoader()
      });
  };


  function formatDate(timestamp) {
    // Parse the timestamp into a Date object
    const date = new Date(timestamp);

    // Format the date as "MMM d, yyyy"
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }

  return (
    <div className="property-table">
      <div className="responsive-table mt-4">
        <Loader />
        <table>
          <thead>
            <tr>
              <th width="50%">Room Name</th>
              <th width="40%">Created Date</th>
              <th width="10%"> Action</th>
            </tr>
          </thead>
          <tbody>
            {roomData?.data?.length > 0 &&
              roomData?.data?.map((ele, index) => {
                return (
                  <tr>
                    <td>{ele?.name}</td>
                    <td>{formatDate(ele?.created_at)}</td>
                    <td>
                      <div className="table-icon">
                        <span onClick={() => handleShow("editRoom", ele)}>
                          <img src="assets/images/edit.svg" alt="back" />
                        </span>
                        <span onClick={() => handleShowModal(ele?.id)}>
                          <img src="assets/images/delete.svg" alt="back" />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}{" "}
          </tbody>
        </table>
        {roomData?.data?.length === 0 && <p className="not-fount">No data found</p>}

        <CustomPagination
          currentPage={currentPage}
          totalPages={roomData?.last_page}
          onPageChange={handlePageChange}
        />

        <ModalComponent
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handler={handleDeleteRoom}
          heading={"Delete room"}
          body={"Are you sure you want to delete this room?"}
        />
      </div>
    </div>
  );
};

export default RoomsTable;
