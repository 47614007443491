import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toastAlert } from "../../utils/toastAlert";
import { emailValidator } from "../../utils/emailValidator";
import { getCookie, setCookie } from "../../utils/cookies";
import { encryptData, decryptData } from "../../utils/encryptionDecryption";
import Axios from "../../axios/axiosInstance";

const SignIn = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePasswordVisibility, setTogglePasswordVisibility] =
    useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    // Check if rememberMe is stored in cookies
    const userData = JSON.parse(getCookie("suser_data"));
    const isAuthenticated = JSON.parse(
      getCookie("DHSAPPauth")
    )?.isAuthenticated;
    const isProfileSetupDone = JSON.parse(
      getCookie("DHSAPPauth")
    )?.isProfileSetupDone;
    console.log("isPRF", isProfileSetupDone);
    if (userData && userData?.rememberMe) {
      setRememberMe(true);
      const decryptedEmail = decryptData(userData?.email);
      const decryptedPassword = decryptData(userData?.password);
      setEmail(decryptedEmail);
      setPassword(decryptedPassword);
    }

    if (isAuthenticated && userData?.token && isProfileSetupDone) {
      navigate("/home");
    } else if (isAuthenticated && userData?.token && !isProfileSetupDone) {
      navigate("/profile-setup");
    }
  }, []);

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const onClickSignIn = (e) => {
    e.preventDefault();
    if (email.length > 0 && password.length > 0) {
      if (emailValidator(email)) {
        Axios.post(
          `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/auth/login`,
          {
            email: email,
            password: password,
          }
        )
          .then((response) => {
            const isProfileSetupDone = response?.data?.user?.profile_setup_done;
            const encryptedEmail = encryptData(email);
            const encryptedPassword = encryptData(password);
            const userData = {
              email: encryptedEmail,
              password: encryptedPassword,
              rememberMe: true,
              token: response?.data?.token,
            };
            if (rememberMe) {
              // Encrypt and store email and password as a single cookie
              setCookie("suser_data", JSON.stringify(userData), 7);
              setCookie(
                "DHSAPPauth",
                JSON.stringify({ isAuthenticated: true }),
                7
              );
              if (isProfileSetupDone === 0) {
                setCookie(
                  "DHSAPPauth",
                  JSON.stringify({
                    isAuthenticated: true,
                    isProfileSetupDone: false,
                  }),
                  7
                );
              } else {
                setCookie(
                  "DHSAPPauth",
                  JSON.stringify({
                    isAuthenticated: true,
                    isProfileSetupDone: true,
                  }),
                  7
                );
              }
            } else {
              const usersData = {
                email: "",
                password: "",
                rememberMe: "",
                token: response.data.token,
              };
              if (isProfileSetupDone === 0) {
                setCookie(
                  "DHSAPPauth",
                  JSON.stringify({
                    isAuthenticated: true,
                    isProfileSetupDone: false,
                  }),
                  7
                );
              } else {
                setCookie(
                  "DHSAPPauth",
                  JSON.stringify({
                    isAuthenticated: true,
                    isProfileSetupDone: true,
                  }),
                  7
                );
              }
              setCookie("suser_data", JSON.stringify(usersData), 7);
            }

            sessionStorage.setItem("user.token", response.data.token);
            toastAlert("Logged in successfully", "success");

            if (isProfileSetupDone === 0) {
              navigate("/profile-setup");
            } else {
              navigate("/home");
            }
          })
          .catch((error) => {
            if (
              error?.response?.data?.message ===
              "These credentials do not match our records."
            ) {
              toastAlert("Please enter valid email or password.", "error");
            } else {
              toastAlert(error?.response?.data?.message, "error");
            }
          });
      } else {
        toastAlert("Please enter the correct email format", "error");
      }
    } else {
      toastAlert("Email and password are required", "error");
    }
  };

  return (
    <>
      <div className="d-flex onboard">
        <div className="banner">
          <img src="assets/images/login-banner.svg" alt="" />
        </div>
        <div className="content">
          <h3>Haus Boss</h3>
          <h2>Welcome Admin</h2>
          <p>Enter details to sign in</p>
          <form onSubmit={onClickSignIn} className="form">
            <div className="form-group">
              <label>
                Email<span className="text-danger"> *</span>
              </label>
              <input
                value={email}
                onChange={handleEmail}
                type="email"
                placeholder="Please enter email"
              />
            </div>
            <div className="form-group ">
              <label>
                Password<span className="text-danger"> *</span>
              </label>
              <input
                value={password}
                onChange={handlePassword}
                placeholder="Please enter password"
                type={togglePasswordVisibility ? "" : "password"}
              />

              {!togglePasswordVisibility ? (
                <img
                  onClick={() =>
                    setTogglePasswordVisibility(!togglePasswordVisibility)
                  }
                  src="assets/images/show.svg"
                  className="hide"
                  alt="showIcon"
                />
              ) : (
                <img
                  onClick={() =>
                    setTogglePasswordVisibility(!togglePasswordVisibility)
                  }
                  src="assets/images/hide.svg"
                  className="hide"
                  alt="hideIcon"
                />
              )}
            </div>

            <div className="form-group mb-0">
              {/* <Link type="submit" className="primery-btn">
                Sign In
              </Link> */}
              <button type="submit" className="primery-btn">
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignIn;
