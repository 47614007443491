// import React from "react";
// import Accordion from "react-bootstrap/Accordion";
// const Contractors = () => {
//   return <>
//     <Accordion
//       // defaultActiveKey="0"
//       className="property_accordian"
//     >
//       <Accordion.Item eventKey="0">
//         <Accordion.Header>
//           <div className="d-flex">
//             <span className="filltik">
//               Appliances{" "}
//               <img src="assets/images/filltik.svg" alt="edit icon" />
//             </span>
//             <span className="edit">
//               {" "}
//               {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//             </span>
//           </div>
//         </Accordion.Header>
//         <Accordion.Body>
//           <div className="appliance">
//             <p>
//               <span>Subcontractor Name</span>
//               <b>Whirlpool</b>
//             </p>
//             <p>
//               <span>Phone Number</span>
//               <b>+1 866-233-8547</b>
//             </p>
//             <p>
//               <span>Email</span>
//               <b>whirlpool@gmail.com</b>
//             </p>
//           </div>
//         </Accordion.Body>
//       </Accordion.Item>
//       <Accordion.Item eventKey="1">
//         <Accordion.Header>
//           <div className="d-flex">
//             <span className="filltik">
//               Plumber <img src="assets/images/filltik.svg" alt="edit icon" />
//             </span>
//             <span className="edit">
//               {" "}
//               {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//             </span>
//           </div>
//         </Accordion.Header>
//         <Accordion.Body>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//           ad ficia deserunt mollit anim id est laborum.
//         </Accordion.Body>
//       </Accordion.Item>
//       <Accordion.Item eventKey="2">
//         <Accordion.Header>
//           <div className="d-flex">
//             <span className="filltik">
//               Electrical{" "}
//               <img src="assets/images/filltik.svg" alt="filltik" />
//             </span>
//             <span className="edit">
//               {" "}
//               {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//             </span>
//           </div>
//         </Accordion.Header>
//         <Accordion.Body>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//           ad ficia deserunt mollit anim id est laborum.
//         </Accordion.Body>
//       </Accordion.Item>
//       <Accordion.Item eventKey="3">
//         <Accordion.Header>
//           <div className="d-flex">
//             <span className="filltik"> Garage Door </span>
//             <span className="edit">
//               {" "}
//               {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//             </span>
//           </div>
//         </Accordion.Header>
//         <Accordion.Body>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//           ad ficia deserunt mollit anim id est laborum.
//         </Accordion.Body>
//       </Accordion.Item>
//       <Accordion.Item eventKey="4">
//         <Accordion.Header>
//           <div className="d-flex">
//             <span className="filltik">Security System </span>
//             <span className="edit">
//               {" "}
//               {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//             </span>
//           </div>
//         </Accordion.Header>
//         <Accordion.Body>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//           ad ficia deserunt mollit anim id est laborum.
//         </Accordion.Body>
//       </Accordion.Item>
//       <Accordion.Item eventKey="5">
//         <Accordion.Header>
//           <div className="d-flex">
//             <span className="filltik">Windows</span>
//             <span className="edit">
//               {" "}
//               {/* <img src="assets/images/edit.svg" alt="edit icon" /> */}
//             </span>
//           </div>
//         </Accordion.Header>
//         <Accordion.Body>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//           ad ficia deserunt mollit anim id est laborum.
//         </Accordion.Body>
//       </Accordion.Item>
//     </Accordion>
//     </>;
// };

// export default Contractors;
import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import { Link } from "react-router-dom";
// import EditContractors from "../forms/EditContractor";
// import EditSupportStaff from "../forms/EditSupportStaff";

const Contractors = ({
  ContractorsAndStaffData,
  propertyId,
  pageRefresh,
  setPageRefresh,
}) => {
  const [contractorDetails, setContractorsDetails] = useState([]);
  // const [show, setShow] = useState(false);
  // const [formView, setFormView] = useState("");
  const [contactId, setContactId] = useState("");

  // const handleClose = () => {
  //   setShow(false);
  // };

  // const handleShow = (form, id) => {
  //   console.log("idddddddddd", id);
  //   setContactId(id);
  //   setFormView(form);
  //   setShow(true);
  // };

  // const handleFormView = () => {
  //   if (formView === "editContractors") {
  //     return (
  //       <>
  //         <Offcanvas.Header>
  //           <Offcanvas.Title>
  //             <h3>
  //               <Link onClick={handleClose} to="#">
  //                 <img src="assets/images/left-arrow.svg" alt="" />
  //               </Link>
  //               Edit Contractor
  //             </h3>
  //           </Offcanvas.Title>
  //         </Offcanvas.Header>
  //         <Offcanvas.Body className="">
  //           {/* <EditContractors
  //             setPageRefresh={setPageRefresh}
  //             pageRefresh={pageRefresh}
  //             // handleClose={handleClose}
  //             propertyId={propertyId}
  //             contactId={contactId}
  //           /> */}
  //         </Offcanvas.Body>
  //       </>
  //     );
  //   }
  // };

  useEffect(() => {
    getContractorList();
    console.log(ContractorsAndStaffData);
  }, [ContractorsAndStaffData]);

  const getContractorList = () => {
    const filterContractor = ContractorsAndStaffData.filter((ele) => {
      return ele.type === "contractor";
    });
    setContractorsDetails(filterContractor);
  };
  console.log("conta", contactId);
  return (
    <>
      <Accordion
        // defaultActiveKey="0"
        className="property_accordian"
      >
        {contractorDetails.length > 0 &&
          contractorDetails?.map((ele, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>
                  <div className="d-flex">
                    <span className="filltik">
                      {ele?.designation.charAt(0).toUpperCase() +
                        ele?.designation.slice(1).toLowerCase()}
                      {ele?.user_type === "App\\Models\\Builder" && (
                        <img src="assets/images/filltik.svg" alt="edit icon" />
                      )}
                    </span>
                    <span className="edit">
                      {" "}
                      {/* <img
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShow("editContractors", ele?.id);
                        }}
                        src="assets/images/edit.svg"
                        alt="edit icon"
                      /> */}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="appliance">
                    <p>
                      <span>Subcontractor Name</span>
                      <b>{ele?.company_name}</b>
                    </p>
                    <p>
                      <span>Phone Number</span>
                      <b>{ele?.company_phonenumber}</b>
                    </p>
                    <p>
                      <span>Email</span>
                      <b>{ele?.company_email}</b>
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        {contractorDetails.length === 0 && (
          <p className="not-fount">No data found</p>
        )}
      </Accordion>
      {/* <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        placement="end "
      >
        {handleFormView()}
      </Offcanvas> */}
    </>
  );
};

export default Contractors;
