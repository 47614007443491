import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PropertiesTable from "./propertiesTable";

const AllProperties = () => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isSearchClicked = location?.state?.isSearchClicked;
  

  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.get("page")) || 1
  );
  const [search, setSearch] = useState(queryParams.get("search") || "");
  const [allocated, setAllocated] = useState(
    queryParams.get("allocated") || ""
  );

  useEffect(() => {
    if (isSearchClicked) {
      document.getElementById("example-search-input").focus();
    }
  }, [isSearchClicked]);

  return (
    <div className="all-property-details">
      <div className="container">
        <div className="upload-title d-flex">
          <div className="backlink">
            {/* to={`/developer/view/${id}` */}
            <Link to={`/developer/view/${id}`}>
              <img src="assets/images/left-arrow.svg" alt="arrow" /> All
              Properties
            </Link>
          </div>
          <div className="search-video" style={{ minWidth: "450px" }}>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentPage(1);
              }}
              class="form-control border rounded-pill search_icon"
              type="text"
              id="example-search-input"
              placeholder="Search properties by name or location"
            />
          </div>
          <div className="all-list-show">
            <select
              onChange={(e) => {
                setAllocated(e.target.value);
                setSearch("");
                setCurrentPage(1);
              }}
              value={allocated}
            >
              <option selected value={""}>
                Show all
              </option>
              <option value={1}>Allocated</option>
              <option value={0}>Unallocated</option>
            </select>
          </div>
        </div>
        <div className="property-table">
          <PropertiesTable
            allocated={allocated}
            userId={id}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            search={search}
            allList={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AllProperties;
