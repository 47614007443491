import Axios from "../axios/axiosInstance";
import { toastAlert } from "./toastAlert";

export const fileUpload = async (file, type, key = "image") => {
  try {
    const formData = new FormData();
    formData.append(key, file);
    formData.append("type", type);

    const uploadThumbnail = await Axios.post(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}builder/storage`,
      formData
    );
    return uploadThumbnail;
  } catch (err) {
    toastAlert(err?.response?.data?.message, "error");
  }
};
