import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { fileUpload } from "../../utils/fileUpload";
import Axios from "../../axios/axiosInstance";
import { toastAlert } from "../../utils/toastAlert";
import useLoader from "../../component/loader/useLoader";
import MapModal from "../../component/modal/MapModal";
import useDesignations from "../../hooks/useDesignations";

const EditAdvertisers = ({ designationData, setPageRefresh, pageRefresh, adId }) => {
  // const { isLoading, error, designationData, fetchDesignations } = useDesignations();
  const { showLoader, hideLoader, Loader } = useLoader();
  const [showModal, setShowModal] = useState(false);
  const [postalCodes, setPostalCodes] = useState(new Set());
  const [verifyZipCode, setVerifyZipCode] = useState({});
  const [verifyZipCodeMap, setVerifyZipCodeMap] = useState({});
  const [paymentPackage, setPaymentPackage] = useState([]);
  const [packageDetails, setPackageDetails] = useState({});
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const packageSelected = watch("subscriptionPackage");
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedPincodes, setSelectedPincodes] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewFile2, setPreviewFile2] = useState(null);
  const upload1 = watch("upload1");
  const upload2 = watch("upload2");
  const contractorType = watch("professionalDesignation");
  const [prevData, setPrevData] = useState();
  const [bannersAvailableZipcodes, setBannerAvailableZipcodes] = useState([]);

  useEffect(() => {
    getPaymentPackages();
  }, []);

  useEffect(() => {
    const p =
      packageSelected &&
      paymentPackage.filter((ele) => {
        return ele.key === packageSelected;
      });
    packageSelected && setPackageDetails(p[0]);
  }, [packageSelected]);

  const getPaymentPackages = async () => {
    try {
      const response = await Axios.get(`advertisers/payment/packages`);

      setPaymentPackage(response.data.packages);
    } catch (error) {
      toastAlert(error?.response?.data?.message, "error");
    } finally {
      // hideLoader();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      // showAdvertiser();
      getDetails();
    }, 400);
  }, []);

  const [verifyBannerZipcode, setVerifyBannerZipcodes] = useState({});
  const getDetails = async () => {
    try {
      const response = await Axios.get(`admin/advertisers/${adId}`);
      const { data } = response.data;
      console.log("resssssss", response.data.advertiser);
      if (response) {
        setValue("advemail", response.data.advertiser.email);
        setValue("professionalDesignation", response.data.advertiser.contractor_type);
        setValue("contactName", response.data.advertiser.contact_name);
        setValue("email", response.data.advertiser.company_email);
        setValue("companyName", response.data.advertiser.company_name);
        setValue("phoneNumber", response.data.advertiser.phonenumber);
        setValue("companyWebsite", response.data.advertiser.company_website);
        setValue("zipCode", response.data.advertiser.zipcode);
        setValue("recommended", response.data.advertiser.recommended);
        setValue("subscriptionPackage", response.data.advertiser.subscription_package);
        const userData = response.data.advertiser;
        const zipcodes = response.data.advertiser.zipcodes;
        const p =
          userData.subscription_package &&
          paymentPackage.filter((ele) => {
            return ele.key === userData.subscription_package;
          });

        userData.subscription_package && setPackageDetails(p[0]);

        setPrevData(userData);

        const logoZipcodes = zipcodes?.map((ele) => ele.zipcode);
        const bannerZipcodes = zipcodes
          ?.filter((ele) => {
            return ele.has_banner === 1;
          })
          ?.map((e) => {
            return e.zipcode;
          });
        setBannerAvailableZipcodes(bannerZipcodes);
        setUpdate(logoZipcodes.length > 0 ? logoZipcodes : []);

        if (logoZipcodes.length > 0) {
          logoZipcodes.forEach((item) => {
            setPostalCodes((prevSet) => {
              const newSet = new Set(prevSet);
              newSet.add(item);
              return newSet;
            });
          });

          setVerifyZipCode((prevVerifyZipCode) => ({
            ...prevVerifyZipCode,
            notInUse: logoZipcodes,
          }));
        }
        if (bannerZipcodes.length > 0) {
          setVerifyBannerZipcodes((prevVerifyZipCode) => ({
            ...prevVerifyZipCode,
            notInUse: bannerZipcodes,
          }));
        }
        // await AddPostalCodes(logoZipcodes);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      // Handle error (e.g., show an error message or log it)
    } finally {
      // hideLoader();
    }
  };

  useEffect(() => {
    const handleUpload = (upload, setPreviewFile) => {
      if (upload && upload[0] instanceof File) {
        setPreviewFile(URL.createObjectURL(upload[0]));
      }
    };

    // trigger("upload1");
    // trigger("upload2");
    if (upload1) {
      handleUpload(upload1, setPreviewFile);
    }
    if (upload2) {
      handleUpload(upload2, setPreviewFile2);
    }

    return () => {
      // Clean up preview files if necessary
      // e.g., URL.revokeObjectURL(previewFile);
    };
  }, [upload1, upload2]);

  const handleResetFile = () => {
    setPreviewFile(null);
    setValue("upload1", "");
    trigger("upload1");
    trigger("upload2");
    setPrevData({ ...prevData, logo: "" });
  };
  const handleResetFile2 = () => {
    setPreviewFile2(null);
    setValue("upload2", "");
    trigger("upload2");
    trigger("upload1");
    setPrevData({ ...prevData, banner: "" });
  };

  const onSubmit = async (data) => {
    const allPinCodes = [];

    if (verifyZipCode.notInUse || verifyZipCode.available) {
      verifyZipCode.available &&
        Object.keys(verifyZipCode?.available).forEach((pincode) => {
          if (verifyZipCode.available[pincode]?.logo === true) {
            allPinCodes.push(pincode);
          }
        });

      // Add notInUse pin codes to the array
      allPinCodes.push(...verifyZipCode?.notInUse);
      if (previewFile && allPinCodes.length === 0) {
        toastAlert("please select zipcodes for logo", "error");
      } else if (previewFile2 && bannersAvailableZipcodes.length === 0) {
        toastAlert("please select zipcodes for banner", "error");
      } else {
        setDisableBtn(true);
        showLoader();
        if ((upload1 && upload1?.length > 0) || (upload2 && upload2?.length)) {
          try {
            let logoKey, bannerKey;

            if (upload1 && upload1?.length > 0) {
              const logoFileUploadResponse = await fileUpload(upload1[0], "advertiser_logo");
              logoKey = logoFileUploadResponse?.data?.key;
            }

            if (upload2 && upload2?.length > 0) {
              const bannerFileUploadResponse = await fileUpload(upload2[0], "advertiser_banner");
              bannerKey = bannerFileUploadResponse?.data?.key;
            }

            await finalUpload(
              data,
              logoKey ? logoKey : null,
              bannerKey ? bannerKey : null,
              allPinCodes
            );
          } catch (error) {
            toastAlert(error.response?.data?.message, "error");
          } finally {
            setDisableBtn(false);
            hideLoader();
          }
        } else {
          await finalUpload(data, null, null, allPinCodes);
        }
      }
    } else {
      toastAlert("Please select zipcodes for logo or banner", "error");
    }
  };

  const finalUpload = async (data, logoKey, bannerKey, allPinCodes) => {
    // console.log("key", key);
    if (allPinCodes.length > 0) {
      showLoader();

      try {
        await Axios.put(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/advertisers/${adId}`, {
          // email: data.advemail,
          // ...(logoKey ? { logo: logoKey } : {}),
          // ...(bannerKey ? { logo: bannerKey } : {}),
          // logo: logoKey || prevData.logo ? (logoKey ? logoKey : prevData.logo) : null,
          // banner: bannerKey || prevData.banner ? (bannerKey ? bannerKey : prevData.banner) : null,
          logo: logoKey ? logoKey : null,
          banner: bannerKey ? bannerKey : null,
          // zipcodes: allPinCodes,
          // banner_zipcodes: selectedPincodes.length > 0 ? selectedPincodes : [],
          // contractor_type: data.professionalDesignation, // Use the form data
          phonenumber: data.phoneNumber,
          // subscription_package: data?.subscriptionPackage,
          contact_name: data.contactName,
          company_name: data.companyName,
          company_email: data.email,
          company_website: data.companyWebsite ? data.companyWebsite : null,
        });
        setPageRefresh(!pageRefresh);
        toastAlert("Advertiser updated successfully", "success");
      } catch (error) {
        toastAlert(error.response?.data?.message, "error");
      } finally {
        hideLoader();
        setDisableBtn(false);
      }
    } else {
      hideLoader();
      toastAlert("Please select zipcodes", "error");
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [update, setUpdate] = useState([]);

  const [pincodesCount, setPincodesCount] = useState(0);
  const AddPostalCodes = async (zip) => {
    // if (zip || (update && update.length > 0)) {
    try {
      const response = await Axios.post(`admin/advertisers/zipcodes/availability`, {
        contractor_type: contractorType ? contractorType : getValues("professionalDesignation"),
        zipcodes: zip.length > 0 ? zip : [...postalCodes],
      });
      console.log("res", response.data);
      const mergedCodes = Object.entries(response.data.available)
        .filter(([code, details]) => details.logo)
        .map(([code]) => code)
        .concat(response.data.notInUse);

      setPincodesCount(mergedCodes.length);
      if (zip.length > 0) {
        setVerifyZipCode(response.data);
      } else if (packageDetails?.zipcodes === 6) {
        if (mergedCodes.length >= packageDetails?.zipcodes) {
          setVerifyZipCode(response.data);
          setShowModal(false);
        } else {
          // toastAlert("Please select 6 or more pincodes");
          const count = packageDetails?.zipcodes - mergedCodes.length;
          setVerifyZipCodeMap(response.data);
          toastAlert(
            `${mergedCodes.length}/${packageDetails?.zipcodes} are available, please select ${
              packageDetails?.zipcodes - mergedCodes.length
            } or more ${count > 1 ? "pincodes" : "pincode"}`,
            "error"
          );
        }
      } else if (mergedCodes.length === packageDetails.zipcodes) {
        setVerifyZipCode(response.data);
        setShowModal(false);
      } else {
        const count = packageDetails.zipcodes - mergedCodes.length;
        setVerifyZipCodeMap(response.data);
        if (packageDetails.zipcodes < mergedCodes.length) {
          toastAlert(
            `Please select only ${packageDetails.zipcodes} ${
              packageDetails.zipcodes === 1 ? "pincode" : "pincodes"
            }`,
            "error"
          );
        } else {
          toastAlert(
            `${mergedCodes.length}/${packageDetails.zipcodes} are available, please select ${
              packageDetails.zipcodes - mergedCodes.length
            } more ${count > 1 ? "pincodes" : "pincode"}`,
            "error"
          );
        }
      }

      // handleClose();
      // toastAlert("Property created successfully", "success");
    } catch (error) {
      // toastAlert(error?.response?.data?.message, "error");
      // if (!zip) {
      //   if (error?.response?.data?.message === "The zipcodes field is required.") {
      //     toastAlert("Please select zipcodes", "error");
      //   } else {
      //     toastAlert(error?.response?.data?.message, "error");
      //   }
      // }
    } finally {
      // hideLoader();
    }
    // } else {
    //   toastAlert("Please select zipcodes", "error");
    // }
  };

  const PincodeList = ({ data }) => {
    const handlePincodeClick = (pincode) => {
      // Check if the pincode is already selected
      if (selectedPincodes.includes(pincode)) {
        // If selected, remove it from the array
        setSelectedPincodes(selectedPincodes.filter((code) => code !== pincode));
      } else {
        // If not selected, add it to the array
        setSelectedPincodes([...selectedPincodes, pincode]);
      }
    };

    return (
      <div className="zip d-flex ">
        {verifyZipCode &&
          verifyZipCode.available &&
          Object.keys(verifyZipCode.available)?.length > 0 &&
          Object?.keys(data?.available).map((pincode) => (
            <span
              key={pincode}
              style={{
                color: data?.available[pincode]?.logo ? "black" : "grey",
                backgroundColor: data?.available[pincode]?.logo ? "#2A9D4A1A" : "none",
                border: selectedPincodes.includes(pincode) ? "2px solid green" : "none",
                cursor: "pointer",
              }}
            >
              {pincode}
            </span>
          ))}
        {data?.notInUse?.length > 0 &&
          data.notInUse.map((pincode) => (
            <span
              key={pincode}
              // onClick={() => {
              //   handlePincodeClick(pincode);
              // }}
              style={{
                backgroundColor: "#2A9D4A1A",
                // border: selectedPincodes.includes(pincode) ? "2px solid green" : "none",
                border: selectedPincodes.includes(pincode) ? "grey" : "none",
                cursor: "pointer",
              }}
            >
              {pincode}
            </span>
          ))}
      </div>
    );
  };

  const BannerPincodeList = ({ data }) => {
    const handlePincodeClick = (pincode) => {
      // Check if the pincode is already selected
      if (selectedPincodes.includes(pincode)) {
        // If selected, remove it from the array
        setSelectedPincodes(selectedPincodes.filter((code) => code !== pincode));
      } else {
        // If not selected, add it to the array
        setSelectedPincodes([...selectedPincodes, pincode]);
      }
    };

    return (
      <div className="zip d-flex">
        {verifyZipCode &&
          verifyZipCode.available &&
          Object.keys(verifyZipCode.available)?.length > 0 &&
          Object?.keys(data?.available).map((pincode) => (
            <span
              key={pincode}
              // onClick={() => {
              //   if (data?.available[pincode]?.banner) {
              //     handlePincodeClick(pincode);
              //   }
              // }}
              style={{
                color: data?.available[pincode]?.banner ? "black" : "grey",
                backgroundColor: data?.available[pincode]?.banner ? "#2A9D4A1A" : "none",
                border: selectedPincodes.includes(pincode) ? "2px solid green" : "none",
                cursor: data?.available[pincode]?.banner && "pointer",
              }}
            >
              {pincode}
            </span>
          ))}
        {data?.notInUse?.length > 0 &&
          data.notInUse.map((pincode) => (
            <span
              key={pincode}
              // onClick={() => {
              //   handlePincodeClick(pincode);
              // }}
              style={{
                backgroundColor: "#2A9D4A1A",
                border: selectedPincodes.includes(pincode) ? "2px solid green" : "none",
                cursor: "pointer",
              }}
            >
              {pincode}
            </span>
          ))}
      </div>
    );
  };

  return (
    <>
      <Loader />
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <div className="add-img-video d-flex align-items-center">
            <div className="left">
              <div className="video-tag">
                <label htmlFor="upload1" className="upload_file">
                  <input
                    // disabled
                    accept="image/*"
                    id="upload1"
                    //  onChange={handleFileUpload}
                    {...register("upload1", {
                      // required:
                      //   !previewFile &&
                      //   !getValues("upload1")?.length > 0 &&
                      //   !previewFile2 &&
                      //   !getValues("upload2")?.length > 0
                      //     ? "Please upload a logo"
                      //     : false,
                      validate: (value) => {
                        if (value[0]) {
                          // if (value && value[0] && upload2 && upload2.length > 0) {
                          //   return "Please select either logo or banner";
                          // }

                          const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
                          if (!allowedTypes.includes(value[0].type)) {
                            return "Only images (JPEG, PNG) are allowed";
                          }
                          return true;
                        }
                      },
                    })}
                    type="file"
                    className="d-none"
                    data-index={0}
                  />

                  {previewFile || prevData?.logo ? (
                    <img
                      src={
                        previewFile
                          ? previewFile
                          : `${process.env.REACT_APP_MEDIA_URL}${prevData?.logo}`
                      }
                      alt=""
                    />
                  ) : (
                    <img src={"assets/images/adv-logo.svg"} alt="" />
                  )}
                </label>
                {(previewFile || prevData?.logo) && (
                  <img
                    onClick={handleResetFile}
                    src="assets/images/cross.svg"
                    className="cross-icon"
                    alt=""
                  />
                )}
                {/* {errors?.upload1?.message && (
                <span role="alert" style={{ color: "red" }}>
                  {errors.upload1.message}
                </span>
              )} */}
              </div>
            </div>
            <span className="m-4"></span>
            <div className="right" style={{ flex: "1" }}>
              {bannersAvailableZipcodes?.length > 0 && (
                <div className="adv-banner">
                  <label htmlFor="upload2" className="upload_file">
                    <input
                      // disabled
                      accept="image/*"
                      id="upload2"
                      //  onChange={handleFileUpload}
                      {...register("upload2", {
                        // required:
                        //   !previewFile &&
                        //   !getValues("upload1")?.length > 0 &&
                        //   !previewFile2 &&
                        //   !getValues("upload2")?.length > 0
                        //     ? "Please upload a logo or banner"
                        //     : false,
                        validate: (value) => {
                          if (value[0]) {
                            // if (value && value[0] && upload1 && upload1.length > 0) {
                            //   return "Please select either logo or banner";
                            // }

                            const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
                            if (!allowedTypes.includes(value[0].type)) {
                              return "Only images (JPEG, PNG) are allowed";
                            }
                            return true;
                          }
                        },
                      })}
                      type="file"
                      className="d-none"
                      data-index={1}
                    />

                    {previewFile2 || prevData?.banner ? (
                      <img
                        src={
                          previewFile2
                            ? previewFile2
                            : `${process.env.REACT_APP_MEDIA_URL}${prevData?.banner}`
                        }
                        alt=""
                        className="w-100 uploaed"
                      />
                    ) : (
                      <img src={"assets/images/adv-banner.svg"} alt="" className="w-100 uploaded" />
                    )}
                  </label>
                  {(previewFile2 || prevData?.banner) && (
                    <img
                      onClick={handleResetFile2}
                      src="assets/images/cross.svg"
                      className="cross-icon"
                      alt=""
                    />
                  )}
                </div>
              )}
              {/* {errors?.upload2?.message && (
                <span role="alert" style={{ color: "red" }}>
                  {errors.upload2.message}
                </span>
              )} */}
            </div>
          </div>
          <span style={{ color: "red" }}>
            {errors?.upload2?.message ||
              (errors?.upload1?.message && (
                <span role="alert" style={{ color: "red" }}>
                  {errors?.upload2?.message || errors?.upload1?.message}
                </span>
              ))}
          </span>
        </div>
        <div className="form-group">
          <label>
            Email<span className="text-danger">*</span>
          </label>
          <input
            disabled
            type="email"
            placeholder="Email"
            {...register("advemail", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Please enter a valid email address",
              },
            })}
          />
          {errors.advemail && <span className="text-danger">{errors.advemail.message}</span>}
        </div>
        <div className="form-group">
          <label> Contractor Type</label>
          <select
            disabled
            {...register("professionalDesignation", {
              required: "contractor type is required",
              validate: (value) => value.trim().length > 0 || "Contractor type is required",
            })}
            placeholder="Contractor Type"
          >
            <option value={""} selected disabled>
              Select contractor type
            </option>
            {designationData?.map((ele) => {
              return (
                <optgroup label={ele.label}>
                  {ele.options.map((e) => {
                    return <option value={e?.slug}>{e?.title}</option>;
                  })}
                </optgroup>
              );
            })}
          </select>
          {errors.professionalDesignation && (
            <span role="alert" style={{ color: "red" }}>
              {errors.professionalDesignation.message}
            </span>
          )}
        </div>
        <div className="d-grid gap-2 reapt-2">
          <div className="form-group">
            <label>
              Contact Name <span className="text-danger">*</span>
            </label>
            <input
              maxLength={50}
              placeholder="Contact name"
              type="text"
              {...register("contactName", {
                required: "Contact name is required",
                validate: (value) => value.trim().length > 0 || "Contact name is required",
              })}
            />
            {errors.contactName && (
              <span className="text-danger">{errors.contactName.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Company Name<span className="text-danger">*</span>
            </label>
            <input
              maxLength={50}
              type="text"
              placeholder="Company name"
              {...register("companyName", {
                required: "Company name is required",
                validate: (value) => value.trim().length > 0 || "Company Name is required",
              })}
            />
            {errors.companyName && (
              <span className="text-danger">{errors.companyName.message}</span>
            )}
          </div>
        </div>
        <div className="d-grid gap-2 reapt-2">
          <div className="form-group">
            <label>
              Phone Number<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="Phone number"
              maxLength={10}
              {...register("phoneNumber", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "Please enter a valid phone number",
                },
                minLength: {
                  value: 10,
                  message: "Phone no. must be 10 digits",
                },
                maxLength: {
                  value: 10,
                  message: "Phone no. must be 10 digits",
                },
                validate: (value) =>
                  value.trim().length === 10 || "Password must be at least 10 characters long",
              })}
            />
            {errors.phoneNumber && (
              <span className="text-danger">{errors.phoneNumber.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Company Email<span className="text-danger">*</span>
            </label>
            <input
              type="email"
              placeholder="Company email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please enter a valid email address",
                },
              })}
            />
            {errors.email && <span className="text-danger">{errors.email.message}</span>}
          </div>
        </div>
        <div className="form-group">
          <label>
            Company Website<span className="text-danger"></span>
          </label>
          <input
            placeholder="https://www.yourcompany.com"
            {...register("companyWebsite", {
              // required: "Company website is required",
              pattern: {
                value:
                  /^(ftp|https?):\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\.[a-zA-Z]{2,})?(:[0-9]{1,5})?(\/.*)?$/,
                message: "Please enter a valid website URL",
              },
            })}
            type="url"
          />
          {errors.companyWebsite && (
            <span className="text-danger">{errors.companyWebsite.message}</span>
          )}
        </div>
        <div className="form-group w-100">
          <label>
            Select your subscription package? <sup>*</sup>
            <span>(See example)</span>
          </label>
          <select
            disabled
            // onChange={() => {
            //   setPostalCodes(new Set());
            //   setVerifyZipCodeMap({});
            //   setVerifyZipCode({});
            //   setUpdate([]);
            // }}
            {...register("subscriptionPackage", {
              required: "Subscription Package is required",
              validate: (value) => value.trim().length > 0 || "Subscription Package is required",
            })}
          >
            <option value={""}>Select subscription package</option>
            {paymentPackage.map((ele) => {
              return <option value={ele.key}>{ele.label}</option>;
            })}
          </select>
          {errors.subscriptionPackage && (
            <span style={{ color: "red" }}>{errors.subscriptionPackage.message}</span>
          )}
        </div>
        <div className="form-group">
          <label>
            Desired Zip Codes for Logo Ads<span className="text-danger">* </span>
          </label>

          <div className="mt-2 ">
            <div>{verifyZipCode && <PincodeList data={verifyZipCode} />}</div>
          </div>
        </div>
        <div>
          {bannersAvailableZipcodes?.length > 0 && (
            <label>Which of your zip codes do you want a banner ad?</label>
          )}
          <div>{verifyZipCode && <BannerPincodeList data={verifyBannerZipcode} />}</div>
        </div>
        <div className="form-group">
          <button type="submit" className="primery-btn">
            Update
          </button>
        </div>
      </form>
      <MapModal
        update={update}
        setUpdate={setUpdate}
        verifyZipCodeMap={verifyZipCodeMap}
        packageDetails={packageDetails}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        postalCodes={postalCodes}
        setPostalCodes={setPostalCodes}
        handler={AddPostalCodes}
        heading={"Zip Code"}
        // body={"Are you sure you want to delete this elevation template?"}
      />
    </>
  );
};

export default EditAdvertisers;
