import React, { useState, useEffect } from "react";
import Axios from "../../../axios/axiosInstance";
import useLoader from "../../../component/loader/useLoader";
import { useNavigate } from "react-router-dom";
const FeatureDetails = ({ featureId, roomId, propertyId, userid }) => {
  const [show, setShow] = useState(false);
  const [formView, setFormView] = useState("");

  const navigate = useNavigate();
  const { showLoader, hideLoader, Loader } = useLoader();
  console.log("feature ifd in new ", featureId);

  const [featureData, setFeatureData] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [roomData, setRoomData] = useState();
  const [itemData, setItemData] = useState("");

  const handleClose = () => {
    //  setCurrentPage(1);
    setShow(false);
    //setPageRefresh(!pageRefresh);
  };

  const handleShow = (form, data) => {
    setFormView(form);
    setShow(true);
    if (data) {
      setItemData(data);
    }
  };

  useEffect(() => {
    getFeatureDetail();
  }, [featureId, show, itemData]);

  const getFeatureDetail = () => {
    showLoader();
    Axios.get(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/${userid}/properties/${propertyId}/rooms/${roomId}/features/${featureId}`
    )
      .then((res) => {
        const fullDescription = res.data.feature?.description;
        setRoomData(res.data.room);
        // Limit the description to 50 words initially
        const limitedDescription = fullDescription ? fullDescription.substring(0, 100) : "";

        setFeatureData({
          ...res.data.feature,
          limitedDescription,
          fullDescription,
        });
      })
      .catch((err) => {})
      .finally((res) => {
        hideLoader();
      });
  };

  const handleReadMore = () => {
    setShowFullDescription((prevState) => !prevState);
  };

  const renderDescription = () => {
    if (showFullDescription) {
      return featureData?.fullDescription;
    } else {
      return featureData?.limitedDescription;
    }
  };

  console.log("feature data", featureData);
  return (
    <>
      <Loader />
      <div className="fire-place">
        <div style={{ backgroundColor: featureData?.color }} className="plus d-flex">
          <span>
            {featureData?.icon && (
              <img src={`${process.env.REACT_APP_MEDIA_URL}${featureData?.icon}`} alt="" />
            )}
          </span>
          <span>
            <b>{featureData?.name}</b>
            <p
              style={{
                color: featureData?.color === "#FEC747" ? "#6d5353" : "",
              }}
            >
              {roomData?.name}
            </p>
          </span>
          {/* {featureData?.user_type === "App\\Models\\Builder" ? (
            <span onClick={() => handleShow("addFeature")}>
              <img src="assets/images/plus.svg" alt="" />
            </span>
          ) : (
          )} */}
          <span></span>
        </div>
        <div className="p-5">
          <div className="important">
            <span>
              What’s Important{" "}
              {/* {featureData?.user_type === "App\\Models\\Builder" && (
                <img
                  onClick={() => handleShow("editFeature")}
                  src="assets/images/edit.svg"
                  alt=""
                />
              )} */}
            </span>
            <p style={{ whiteSpace: "pre-line" }}>
              {renderDescription()}
              {featureData?.fullDescription && (
                <a
                  style={{ color: featureData?.color, cursor: "pointer" }}
                  onClick={handleReadMore}
                >
                  {featureData.description?.length > 100 && (
                    <>{showFullDescription ? "Read Less" : "Read More..."}</>
                  )}
                </a>
              )}
            </p>
          </div>
          <div className="how-to">
            <span>How to’s</span>
            {/* <div className="d-flex">
              <div className="left">
                <img src="assets/images/howto.png" alt="" />
              </div>
              <div className="right">
                <h4>How to start the fire</h4>
                <p>
                  Neque porro quisquam est qui dolorem ipsum quia dolor sit
                  amet, consectet
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="left">
                <img src="assets/images/howto.png" />
              </div>
              <div className="right">
                <h4>How to start the fire</h4>
                <p>
                  Neque porro quisquam est qui dolorem ipsum quia dolor sit
                  amet, consectet
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="left">
                <img src="assets/images/howto.png" />
              </div>
              <div className="right">
                <h4>How to start the fire</h4>
                <p>
                  Neque porro quisquam est qui dolorem ipsum quia dolor sit
                  amet, consectet
                </p>
              </div>
            </div> */}
            {featureData?.items?.length > 0 &&
              featureData?.items?.map((ele, index) => {
                const images = ele?.attachments?.filter(
                  (image) =>
                    image?.source?.includes(".png") ||
                    image?.source?.includes(".jpeg") ||
                    image?.source?.includes(".jpg")
                );
                const onlyMp3 = ele?.attachments?.filter(
                  (file) =>
                    (!file?.source?.includes(".png") &&
                      !file?.source?.includes(".jpeg") &&
                      !file?.source?.includes(".jpg") &&
                      !file?.source?.includes(".mp4")) ||
                    file?.source?.includes(".mp3")
                );

                const firstImage = images.length > 0 ? images[0] : null;

                return (
                  <div
                    className="d-flex"
                    onClick={() => {
                      navigate("/feature-item-details", {
                        state: ele?.attachments,
                      });
                    }}
                    key={index}
                  >
                    <div className="left">
                      {/* {firstImage?.source ? (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}${firstImage?.source}`}
                          alt="img"
                        />
                      ) : onlyMp3?.length > 0 ? (
                        <img
                          style={{ backgroundColor: "#F3F4F6" }}
                          src="assets/images/music.svg"
                          alt="img"
                        />
                      ) : (
                        <img src="assets/images/howto.png" alt="" />
                      )} */}
                      {ele?.attachments?.length > 0 ? (
                        <>
                          {firstImage?.source ? (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_URL}${firstImage?.source}`}
                              alt="img"
                            />
                          ) : onlyMp3?.length > 0 ? (
                            <div className="playmusic" style={{ backgroundColor: "#F3F4F6" }}>
                              <img src="assets/images/playmusic.svg" alt="img" />
                            </div>
                          ) : (
                            <img src="assets/images/howto.png" alt="" />
                          )}
                        </>
                      ) : (
                        <img src="assets/images/dress.svg" alt="" />
                      )}
                    </div>
                    <div className="right">
                      <h4>
                        {ele.title}{" "}
                        {/* {featureData?.user_type === "App\\Models\\Builder" && (
                          <img
                            style={{ cursor: "pointer" }}
                            className="p-1"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShow("editFeatureItem", ele);
                            }}
                            src="assets/images/edit.svg"
                            alt=""
                          />
                        )} */}
                      </h4>
                      <p style={{ whiteSpace: "pre-line" }}>{ele?.description}</p>
                    </div>
                  </div>
                );
              })}
            {featureData?.items?.length === 0 && <p className="not-fount">No data found</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureDetails;

// import React, { useState, useEffect } from "react";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import { Link, useNavigate } from "react-router-dom";

// import Axios from "../../../axios/axiosInstance";
// import useLoader from "../../../component/loader/useLoader";

// const FeatureDetails = ({
//   featureId,
//   currentPage,
//   setCurrentPage,
//   roomId,
//   propertyId,
//   setPageRefresh,
//   pageRefresh,
// }) => {
//   const navigate = useNavigate();
//   const { showLoader, hideLoader, Loader } = useLoader();
//   console.log("feature ifd in new ", featureId);
//   const [show, setShow] = useState(false);
//   const [formView, setFormView] = useState("");
//   const [featureData, setFeatureData] = useState([]);
//   const [showFullDescription, setShowFullDescription] = useState(false);
//   const [roomData, setRoomData] = useState();
//   const [itemData, setItemData] = useState("");

//   const handleClose = () => {
//     setCurrentPage(1);
//     setShow(false);
//     setPageRefresh(!pageRefresh);
//   };

//   const handleShow = (form, data) => {
//     setFormView(form);
//     setShow(true);
//     if (data) {
//       setItemData(data);
//     }
//   };

//   useEffect(() => {
//     getFeatureDetail();
//   }, [featureId, pageRefresh, show, itemData]);

//   const getFeatureDetail = () => {
//     showLoader();
//     Axios.get(
//       `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/3/properties/${propertyId}/rooms/${roomId}/features/${featureId}`
//     )
//       .then((res) => {
//         const fullDescription = res.data.feature?.description;
//         setRoomData(res.data.room);
//         // Limit the description to 50 words initially
//         const limitedDescription = fullDescription
//           ? fullDescription.substring(0, 100)
//           : "";

//         setFeatureData({
//           ...res.data.feature,
//           limitedDescription,
//           fullDescription,
//         });
//       })
//       .catch((err) => {})
//       .finally((res) => {
//         hideLoader();
//       });
//   };

//   const handleReadMore = () => {
//     setShowFullDescription((prevState) => !prevState);
//   };

//   const renderDescription = () => {
//     if (showFullDescription) {
//       return featureData?.fullDescription;
//     } else {
//       return featureData?.limitedDescription;
//     }
//   };

//   const handleFormView = () => {
//     if (formView === "addFeature") {
//       return (
//         <>
//           <Offcanvas.Header>
//             <Offcanvas.Title>
//               <h3>
//                 <Link onClick={handleClose} to="#">
//                   <img src="assets/images/left-arrow.svg" alt="" />
//                 </Link>
//                 Add feature
//               </h3>
//             </Offcanvas.Title>
//           </Offcanvas.Header>
//           <Offcanvas.Body className="">
//             {/* <AddFeature handleClose={handleClose} featureId={featureId} /> */}
//           </Offcanvas.Body>
//         </>
//       );
//     } else if (formView === "editFeature") {
//       return (
//         <>
//           <Offcanvas.Header>
//             <Offcanvas.Title>
//               <h3>
//                 <Link onClick={handleClose} to="#">
//                   <img src="assets/images/left-arrow.svg" alt="" />
//                 </Link>
//                 Edit feature
//               </h3>
//             </Offcanvas.Title>
//           </Offcanvas.Header>
//           <Offcanvas.Body className="">
//             {/* <EditFeature
//               handleClose={handleClose}
//               propertyId={propertyId}
//               roomData={roomData}
//               featureId={featureId}
//               featureData={featureData}
//               setFeatureData={setFeatureData}
//             /> */}
//           </Offcanvas.Body>
//         </>
//       );
//     } else {
//       return (
//         <>
//           <Offcanvas.Header>
//             <Offcanvas.Title>
//               <h3>
//                 <Link onClick={handleClose} to="#">
//                   <img src="assets/images/left-arrow.svg" alt="" />
//                 </Link>
//                 Edit feature Item
//               </h3>
//             </Offcanvas.Title>
//           </Offcanvas.Header>
//           <Offcanvas.Body className="">
//             {/* <EditFeatureItem
//               handleShow={handleShow}
//               handleClose={handleClose}
//               featureId={featureId}
//               itemData={itemData}
//             /> */}
//           </Offcanvas.Body>
//         </>
//       );
//     }
//   };

//   return (
//     <>
//       <Loader />
//       <div className="fire-place">
//         <div
//           style={{ backgroundColor: featureData?.color }}
//           className="plus d-flex"
//         >
//           <span>
//             {featureData?.icon && (
//               <img
//                 src={`${process.env.REACT_APP_MEDIA_URL}${featureData?.icon}`}
//                 alt=""
//               />
//             )}
//           </span>
//           <span>
//             <b>{featureData?.name}</b>
//             <p>{roomData?.name}</p>
//           </span>
//           {featureData?.user_type === "App\\Models\\Builder" ? (
//             <span onClick={() => handleShow("addFeature")}>
//               <img src="assets/images/plus.svg" alt="" />
//             </span>
//           ) : (
//             <span></span>
//           )}
//         </div>
//         <div className="p-5">
//           <div className="important">
//             <span>
//               What’s Important{" "}
//               {featureData?.user_type === "App\\Models\\Builder" && (
//                 <img
//                   onClick={() => handleShow("editFeature")}
//                   src="assets/images/edit.svg"
//                   alt=""
//                 />
//               )}
//             </span>
//             <p style={{ whiteSpace: "pre-line" }}>
//               {renderDescription()}
//               {featureData?.fullDescription && (
//                 <a
//                   style={{ color: featureData?.color, cursor: "pointer" }}
//                   onClick={handleReadMore}
//                 >
//                   {featureData.description?.length > 100 && (
//                     <>{showFullDescription ? "Read Less" : "Read More..."}</>
//                   )}
//                 </a>
//               )}
//             </p>
//           </div>
//           <div className="how-to ">
//             <span>How to’s</span>

//             {featureData?.items?.length > 0 &&
//               featureData?.items?.map((ele, index) => {
//                 const images = ele?.attachments?.filter(
//                   (image) =>
//                     image?.source?.includes(".png") ||
//                     image?.source?.includes(".jpeg") ||
//                     image?.source?.includes(".jpg")
//                 );
//                 const firstImage = images.length > 0 ? images[0] : null;

//                 return (
//                   <div
//                     className="d-flex"
//                     onClick={() => {
//                       navigate(
//                         "/feature-item-details/" +
//                           ele?.id +
//                           "/" +
//                           ele?.feature_id,
//                         { state: ele?.attachments }
//                       );
//                     }}
//                     key={index}
//                   >
//                     <div className="left">
//                       {firstImage?.source ? (
//                         <img
//                           src={`${process.env.REACT_APP_MEDIA_URL}${firstImage?.source}`}
//                           alt="img"
//                         />
//                       ) : (
//                         <img src="assets/images/howto.png" alt="" />
//                       )}
//                     </div>
//                     <div className="right">
//                       <h4>
//                         {ele.title}{" "}
//                         {featureData?.user_type === "App\\Models\\Builder" && (
//                           <img
//                             style={{ cursor: "pointer" }}
//                             className="p-1"
//                             onClick={(e) => {
//                               e.stopPropagation();
//                               handleShow("editFeatureItem", ele);
//                             }}
//                             src="assets/images/edit.svg"
//                             alt=""
//                           />
//                         )}
//                       </h4>
//                       <p>{ele.description}</p>
//                     </div>
//                   </div>
//                 );
//               })}
//             {featureData?.items?.length === 0 && (
//               <p className="not-fount">No data found</p>
//             )}
//           </div>
//         </div>
//       </div>
//       <Offcanvas
//         show={show}
//         onHide={handleClose}
//         backdrop={false}
//         placement="end"
//       >
//         {handleFormView()}
//       </Offcanvas>
//     </>
//   );
// };

// export default FeatureDetails;
