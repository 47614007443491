import React, { useState } from "react";
import RoomsTable from "./RoomsTable";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import CreateRoomForm from "./CreateRoomForm";
import EditRoomForm from "./EditRoom";
const Rooms = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [formView, setFormView] = useState("");
  const [roomDetail, setRoomDetail] = useState();
  const [search, setSearch] = useState("");

  const handleClose = () => setShow(false);

  const handleShow = (form, roomData) => {
    setFormView(form);
    setShow(true);
    if (roomData) {
      setRoomDetail(roomData);
    }
  };

  const handleFormView = () => {
    if (formView === "createRoom") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                Create New Room
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CreateRoomForm handleClose={handleClose} />
          </Offcanvas.Body>
        </>
      );
    } else if (formView === "editRoom") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                Edit Room
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditRoomForm
              setSearch={setSearch}
              roomDetail={roomDetail}
              handleClose={handleClose}
            />
          </Offcanvas.Body>
        </>
      );
    }
  };


  return (
    <>
      <div className="all-property rooms">
        <div className="container">
          <div class="d-flex title">
            <h2>Rooms</h2>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentPage(1);
              }}
              type="search"
              placeholder="Search room by name"
            />

            <button onClick={() => handleShow("createRoom")} className="primery-btn"><img src="assets/images/create-new-rooms.svg" alt="back" /> Create New Room</button>
          </div>
       
        <RoomsTable
          search={search}
          roomDetail={roomDetail}
          handleShow={handleShow}
          show={show}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        placement="end "
      >
        {handleFormView()}
      </Offcanvas>
    </>
  );
};

export default Rooms;
