import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Approved from "./Approved";
import NotApproved from "./NotApproved";
import Offcanvas from "react-bootstrap/Offcanvas";
import CreateAdvertisers from "./CreateAdvertisers";
import Ads from "./Ads";
import { Link } from "react-router-dom";
import useDesignations from "../../hooks/useDesignations";
import EditAdvertisers from "./EditAdvertisers";
import ViewDetails from "./ViewDetails";
import Axios from "../../axios/axiosInstance";

const Advertisers = () => {
  const { isLoading, error, designationData, fetchDesignations } = useDesignations();
  const [key, setKey] = useState("approved");
  const [formView, setFormView] = useState("");

  const [show, setShow] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [adId, setAdId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (form) => {
    setFormView(form);
    setShow(true);
  };

  useEffect(() => {
    fetchDesignations();
    getCount();
    handleClose();
  }, [pageRefresh]);

  const [stats, setStats] = useState();
  const getCount = () => {
    Axios.get(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/advertisers/count`)
      .then((res) => {
        setStats(res.data);
      })
      .catch((err) => {
        // toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        // hideLoader();
      });
  };

  const handleFormView = () => {
    if (formView === "create") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                Create Advertisers
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CreateAdvertisers
              setKey={setKey}
              designationData={designationData}
              pageRefresh={pageRefresh}
              setPageRefresh={setPageRefresh}
            />
          </Offcanvas.Body>
        </>
      );
    } else if (formView === "edit") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                Edit Advertisers
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditAdvertisers
              designationData={designationData}
              adId={adId}
              pageRefresh={pageRefresh}
              setPageRefresh={setPageRefresh}
            />
          </Offcanvas.Body>
        </>
      );
    } else if (formView === "AdsEdit") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                Edit Ads
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditAdvertisers
              designationData={designationData}
              adId={adId}
              pageRefresh={pageRefresh}
              setPageRefresh={setPageRefresh}
            />
          </Offcanvas.Body>
        </>
      );
    } else if (formView === "viewDetaills") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                Advertiser Detail
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ViewDetails designationData={designationData} adId={adId} />
          </Offcanvas.Body>
        </>
      );
    }
  };

  const flattenData = designationData?.reduce((acc, category) => {
    acc.push(...category.options);
    return acc;
  }, []);

  function findTitleBySlug(slug) {
    const foundOption = flattenData?.find((option) => option?.slug === slug);
    return foundOption ? foundOption?.title : null;
  }
  return (
    <>
      <div className="advertise">
        <div className="container">
          <div className="d-flex position-relative">
            <div className="tab-box w-100">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="approved" title="Approved">
                  {key === "approved" && (
                    <Approved
                      findTitleBySlug={findTitleBySlug}
                      handleShow={handleShow}
                      pageRefresh={pageRefresh}
                      setPageRefresh={setPageRefresh}
                      adId={adId}
                      setAdId={setAdId}
                    />
                  )}
                </Tab>
                <Tab eventKey="notApproved" title="Not Approved">
                  {key === "notApproved" && (
                    <NotApproved
                      findTitleBySlug={findTitleBySlug}
                      handleShow={handleShow}
                      pageRefresh={pageRefresh}
                      setPageRefresh={setPageRefresh}
                      adId={adId}
                      setAdId={setAdId}
                    />
                  )}
                </Tab>
                <Tab eventKey="ads" title="Ads">
                  {key === "ads" && (
                    <Ads
                      findTitleBySlug={findTitleBySlug}
                      handleShow={handleShow}
                      pageRefresh={pageRefresh}
                      setPageRefresh={setPageRefresh}
                      adId={adId}
                      setAdId={setAdId}
                    />
                  )}
                </Tab>
              </Tabs>
            </div>

            {key === "ads" ? (
              <div className="view-on-map">
                <Link>View Available Spot on map</Link>
              </div>
            ) : (
              <div className="property">
                <span className="n-p" style={{ backgroundColor: "#E4ECEB", width: "182px" }}>
                  {" "}
                  <p>Total Advertisers</p>
                  <b>{stats?.advertiser_count || 0}</b>
                </span>
                <span className="n-p" style={{ backgroundColor: "#FFF0CD", width: "182px" }}>
                  {" "}
                  <p>Total Ad's</p>
                  <b>{stats?.ads_count || 0}</b>
                </span>
                <span className="n-p" style={{ backgroundColor: "#FFF1E7", width: "182px" }}>
                  {" "}
                  <p>Total Unfilled Ad's</p>
                  <b>{stats?.ads_unfilled_count || 0}</b>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} backdrop={true} placement="end">
        {handleFormView()}
      </Offcanvas>
    </>
  );
};

export default Advertisers;
