import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { fileUpload } from "../../utils/fileUpload";
import Axios from "../../axios/axiosInstance";
import { toastAlert } from "../../utils/toastAlert";
import useLoader from "../../component/loader/useLoader";
import MapModal from "../../component/modal/MapModal";
import { upload } from "@testing-library/user-event/dist/upload";

const CreateAdvertisers = ({ setPageRefresh, pageRefresh, designationData, setKey }) => {
  const { showLoader, hideLoader, Loader } = useLoader();
  const [showModal, setShowModal] = useState(false);
  const [postalCodes, setPostalCodes] = useState(new Set());
  const [verifyZipCode, setVerifyZipCode] = useState({});
  const [verifyZipCodeMap, setVerifyZipCodeMap] = useState({});
  const [prevData, setPrevData] = useState();
  const [paymentPackage, setPaymentPackage] = useState([]);
  const [packageDetails, setPackageDetails] = useState({});
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const packageSelected = watch("subscriptionPackage");
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedPincodes, setSelectedPincodes] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewFile2, setPreviewFile2] = useState(null);
  const upload1 = watch("upload1");
  const upload2 = watch("upload2");
  const contractorType = watch("professionalDesignation");

  useEffect(() => {
    getPaymentPackages();
  }, []);

  useEffect(() => {
    const p =
      packageSelected &&
      paymentPackage.filter((ele) => {
        return ele.key === packageSelected;
      });
    packageSelected && setPackageDetails(p[0]);
  }, [packageSelected]);

  const getPaymentPackages = async () => {
    try {
      const response = await Axios.get(`advertisers/payment/packages`);

      setPaymentPackage(response.data.packages);
    } catch (error) {
      // toastAlert(error?.response?.data?.message, "error");
    } finally {
      // hideLoader();
    }
  };

  useEffect(() => {
    console.log("upload q", upload1);
    const handleUpload = (upload, setPreviewFile) => {
      if (upload && upload[0] instanceof File) {
        setPreviewFile(URL.createObjectURL(upload[0]));
      }
    };

    if (upload1) {
      handleUpload(upload1, setPreviewFile);
      // trigger("upload1");
      // trigger("upload2");
    }
    if (upload2) {
      handleUpload(upload2, setPreviewFile2);
      // trigger("upload1");
      // trigger("upload2");
    }

    return () => {
      // Clean up preview files if necessary
      // e.g., URL.revokeObjectURL(previewFile);
    };
  }, [upload1, upload2]);

  const handleResetFile = () => {
    setPreviewFile(null);
    setValue("upload1", "");
    trigger("upload1");
    trigger("upload2");
  };
  const handleResetFile2 = () => {
    setPreviewFile2(null);
    setValue("upload2", "");
    trigger("upload2");
    trigger("upload1");
  };

  const onSubmit = async (data) => {
    const allPinCodes = [];

    if (verifyZipCode.notInUse || verifyZipCode.available) {
      verifyZipCode.available &&
        Object.keys(verifyZipCode?.available).forEach((pincode) => {
          if (verifyZipCode.available[pincode]?.logo === true) {
            allPinCodes.push(pincode);
          }
        });

      // Add notInUse pin codes to the array
      allPinCodes.push(...verifyZipCode?.notInUse);
      if (previewFile && allPinCodes.length === 0) {
        toastAlert("please select zipcodes for logo", "error");
      } else if (previewFile2 && selectedPincodes.length === 0) {
        toastAlert("please select zipcodes for banner", "error");
      } else {
        setDisableBtn(true);
        showLoader();
        if ((upload1 && upload1?.length > 0) || (upload2 && upload2?.length)) {
          try {
            // const fileUploadResponse = await fileUpload(
            //   upload1 && upload1?.length > 0 ? upload1[0] : upload2[0],
            //   upload1 && upload1?.length > 0 ? "advertiser_logo" : "advertiser_banner"
            // );
            // if (fileUploadResponse) {
            //   console.log("fileUploadResponse", fileUploadResponse);
            //   await finalUpload(
            //     data,
            //     fileUploadResponse?.data?.key,
            //     upload1 && upload1.length > 0 ? "logo" : "banner",
            //     allPinCodes
            //   );
            // }
            let logoKey, bannerKey;

            if (upload1 && upload1?.length > 0) {
              const logoFileUploadResponse = await fileUpload(upload1[0], "advertiser_logo");
              logoKey = logoFileUploadResponse?.data?.key;
            }

            if (upload2 && upload2?.length > 0) {
              const bannerFileUploadResponse = await fileUpload(upload2[0], "advertiser_banner");
              bannerKey = bannerFileUploadResponse?.data?.key;
            }

            // Combine both logo and banner keys
            const combinedKeys = [logoKey, bannerKey].filter(Boolean);
            console.log("combined ", combinedKeys);
            // Call finalUpload only once with both keys
            await finalUpload(
              data,
              logoKey ? logoKey : null,
              bannerKey ? bannerKey : null,
              allPinCodes
            );
          } catch (error) {
            toastAlert(error.response?.data?.message, "error");
          } finally {
            setDisableBtn(false);
            hideLoader();
          }
        } else {
          await finalUpload(data, null, null, allPinCodes);
        }
      }
    } else {
      toastAlert("Please select zipcodes for logo or banner", "error");
    }
  };

  const finalUpload = async (data, logoKey, bannerKey, allPinCodes) => {
    // console.log("key", key);
    if (allPinCodes.length > 0) {
      showLoader();

      try {
        await Axios.post(`${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/advertisers`, {
          email: data.advemail,
          logo: logoKey ? logoKey : null,
          banner: bannerKey ? bannerKey : null,
          zipcodes: allPinCodes,
          banner_zipcodes: selectedPincodes.length > 0 ? selectedPincodes : [],
          contractor_type: data.professionalDesignation, // Use the form data
          phonenumber: data.phoneNumber,
          subscription_package: data?.subscriptionPackage,
          subscription_activate: true,
          // image: key,
          contact_name: data.contactName,
          company_name: data.companyName,
          company_email: data.email,
          company_website: data.companyWebsite ? data.companyWebsite : null,
          // recommended: data.recommended ? true : false,
          image_display: previewFile ? "advertiser_logo" : "advertiser_banner",
        });
        setPageRefresh(!pageRefresh);
        setKey("approved");
        toastAlert("Advertiser created successfully", "success");
      } catch (error) {
        toastAlert(error.response?.data?.message, "error");
      } finally {
        hideLoader();
        setDisableBtn(false);
      }
    } else {
      hideLoader();
      toastAlert("Please select zipcodes", "error");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [update, setUpdate] = useState([]);

  const [pincodesCount, setPincodesCount] = useState(0);
  const AddPostalCodes = async (zip) => {
    // if (zip || (update && update.length > 0)) {
    try {
      const response = await Axios.post(`admin/advertisers/zipcodes/availability`, {
        contractor_type: contractorType,
        zipcodes: zip.length > 0 ? zip : [...postalCodes],
      });
      console.log("res", response.data);
      const mergedCodes = Object.entries(response.data.available)
        .filter(([code, details]) => details.logo)
        .map(([code]) => code)
        .concat(response.data.notInUse);

      setPincodesCount(mergedCodes.length);
      if (zip.length > 0) {
        setVerifyZipCode(response.data);
      } else if (packageDetails?.zipcodes === 6) {
        if (mergedCodes.length >= packageDetails?.zipcodes) {
          setVerifyZipCode(response.data);
          setShowModal(false);
        } else {
          // toastAlert("Please select 6 or more pincodes");
          const count = packageDetails?.zipcodes - mergedCodes.length;
          setVerifyZipCodeMap(response.data);
          toastAlert(
            `${mergedCodes.length}/${packageDetails?.zipcodes} are available, please select ${
              packageDetails?.zipcodes - mergedCodes.length
            } or more ${count > 1 ? "pincodes" : "pincode"}`,
            "error"
          );
        }
      } else if (mergedCodes.length === packageDetails.zipcodes) {
        setVerifyZipCode(response.data);
        setShowModal(false);
      } else {
        const count = packageDetails.zipcodes - mergedCodes.length;
        setVerifyZipCodeMap(response.data);
        if (packageDetails.zipcodes < mergedCodes.length) {
          toastAlert(
            `Please select only ${packageDetails.zipcodes} ${
              packageDetails.zipcodes === 1 ? "pincode" : "pincodes"
            }`,
            "error"
          );
        } else {
          toastAlert(
            `${mergedCodes.length}/${packageDetails.zipcodes} are available, please select ${
              packageDetails.zipcodes - mergedCodes.length
            } more ${count > 1 ? "pincodes" : "pincode"}`,
            "error"
          );
        }
      }

      // handleClose();
      // toastAlert("Property created successfully", "success");
    } catch (error) {
      // toastAlert(error?.response?.data?.message, "error");
      // if (!zip) {
      //   if (error?.response?.data?.message === "The zipcodes field is required.") {
      //     toastAlert("Please select zipcodes", "error");
      //   } else {
      //     toastAlert(error?.response?.data?.message, "error");
      //   }
      // }
    } finally {
      // hideLoader();
    }
    // } else {
    //   toastAlert("Please select zipcodes", "error");
    // }
  };

  const PincodeList = ({ data }) => {
    const handlePincodeClick = (pincode) => {
      // Check if the pincode is already selected
      if (selectedPincodes.includes(pincode)) {
        // If selected, remove it from the array
        setSelectedPincodes(selectedPincodes.filter((code) => code !== pincode));
      } else {
        // If not selected, add it to the array
        setSelectedPincodes([...selectedPincodes, pincode]);
      }
    };

    return (
      <div className="zip d-flex ">
        {verifyZipCode &&
          verifyZipCode.available &&
          Object.keys(verifyZipCode.available)?.length > 0 &&
          Object?.keys(data?.available).map((pincode) => (
            <span
              key={pincode}
              style={{
                color: data?.available[pincode]?.logo ? "black" : "grey",
                backgroundColor: data?.available[pincode]?.logo ? "#2A9D4A1A" : "none",
                // border: selectedPincodes.includes(pincode) ? "2px solid green" : "none",
                cursor: "pointer",
              }}
            >
              {pincode}
            </span>
          ))}
        {data?.notInUse?.length > 0 &&
          data.notInUse.map((pincode) => (
            <span
              key={pincode}
              // onClick={() => {
              //   handlePincodeClick(pincode);
              // }}
              style={{
                backgroundColor: "#2A9D4A1A",
                // border: selectedPincodes.includes(pincode) ? "2px solid green" : "none",
                border: selectedPincodes.includes(pincode) ? "grey" : "none",
                cursor: "pointer",
              }}
            >
              {pincode}
            </span>
          ))}
        {/* {data?.notAvailable.map((pincode) => (
          <span
            key={pincode}
            // onClick={() => {
            //   if (data?.notAvailable[pincode]?.banner) {
            //     handlePincodeClick(pincode);
            //   }
            // }}
            style={{
              color: "grey",
              border:
                selectedPincodes.includes(pincode) && data?.notAvailable[pincode]?.banner
                  ? "2px solid green"
                  : "none",
              cursor: "pointer",
            }}
          >
            {pincode + ","}
          </span>
        ))} */}
      </div>
    );
  };

  const BannerPincodeList = ({ data }) => {
    const handlePincodeClick = (pincode) => {
      // Check if the pincode is already selected
      if (selectedPincodes.includes(pincode)) {
        // If selected, remove it from the array
        setSelectedPincodes(selectedPincodes.filter((code) => code !== pincode));
      } else {
        // If not selected, add it to the array
        setSelectedPincodes([...selectedPincodes, pincode]);
      }
    };

    return (
      <div className="zip d-flex">
        {verifyZipCode &&
          verifyZipCode.available &&
          Object.keys(verifyZipCode.available)?.length > 0 &&
          Object?.keys(data?.available).map((pincode) => (
            <span
              key={pincode}
              onClick={() => {
                if (data?.available[pincode]?.banner) {
                  handlePincodeClick(pincode);
                }
              }}
              style={{
                color: data?.available[pincode]?.banner
                  ? selectedPincodes.includes(pincode)
                    ? "white"
                    : "black"
                  : "grey",
                backgroundColor: data?.available[pincode]?.banner
                  ? selectedPincodes.includes(pincode)
                    ? "#045C46"
                    : "#2A9D4A1A"
                  : "none",
                border: selectedPincodes.includes(pincode) ? "2px solid #045C46" : "none",
                cursor: data?.available[pincode]?.banner && "pointer",
              }}
            >
              {pincode}
            </span>
          ))}
        {data?.notInUse?.length > 0 &&
          data.notInUse.map((pincode) => (
            <span
              key={pincode}
              onClick={() => {
                handlePincodeClick(pincode);
              }}
              style={{
                color: selectedPincodes.includes(pincode) ? "white" : "black",
                backgroundColor: selectedPincodes.includes(pincode) ? "#045C46" : "#2A9D4A1A",
                border: selectedPincodes.includes(pincode) ? "2px solid #045C46" : "none",
                cursor: "pointer",
              }}
            >
              {pincode}
            </span>
          ))}
        {/* {Object?.keys(data?.notAvailable).map((pincode) => (
          <span
            key={pincode}
            onClick={() => {
              if (data?.notAvailable[pincode]?.banner) {
                handlePincodeClick(pincode);
              }
            }}
            style={{
              color: data?.notAvailable[pincode]?.banner ? "green" : "grey",
              border:
                selectedPincodes.includes(pincode) && data?.notAvailable[pincode]?.banner
                  ? "2px solid green"
                  : "none",
              cursor: "pointer",
            }}
          >
            {pincode + ","}
          </span>
        ))} */}
      </div>
    );
  };

  return (
    <>
      <Loader />
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <div className="add-img-video d-flex align-items-center">
            <div className="left">
              <div className="video-tag">
                <label htmlFor="upload1" className="upload_file">
                  <input
                    id="upload1"
                    //  onChange={handleFileUpload}
                    {...register("upload1", {
                      // required:
                      //   !previewFile &&
                      //   !getValues("upload1")?.length > 0 &&
                      //   !previewFile2 &&
                      //   !getValues("upload2")?.length > 0
                      //     ? "Please upload a logo or banner"
                      //     : false
                      // ,

                      validate: (value) => {
                        if (value[0]) {
                          // if (value && value[0] && upload2 && upload2.length > 0) {
                          //   return "Please select either logo or banner";
                          // }
                          if (!value || !value[0]) return "Property image is required";
                          const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
                          if (!allowedTypes.includes(value[0].type)) {
                            return "Only images (JPEG, PNG) are allowed";
                          }
                          return true;
                        }
                      },
                    })}
                    type="file"
                    className="d-none"
                    data-index={0}
                    accept="image/*"
                  />
                  <img src={previewFile ? previewFile : "assets/images/adv-logo.svg"} alt="" />
                </label>
                {previewFile && (
                  <img
                    onClick={handleResetFile}
                    src="assets/images/cross.svg"
                    className="cross-icon"
                    alt=""
                  />
                )}
              </div>
              {/* {errors?.upload1?.message && (
                <span role="alert" style={{ color: "red" }}>
                  {errors.upload1.message}
                </span>
              )} */}
            </div>
            <span className="m-4"></span>
            <div className="right" style={{ flex: "1" }}>
              <div className="adv-banner">
                <label htmlFor="upload2" className="upload_file">
                  <input
                    id="upload2"
                    //  onChange={handleFileUpload}
                    {...register("upload2", {
                      // required:
                      //   !previewFile &&
                      //   !getValues("upload1")?.length > 0 &&
                      //   !previewFile2 &&
                      //   !getValues("upload2")?.length > 0
                      //     ? "Please upload a logo or banner"
                      //     : false,
                      validate: (value) => {
                        if (value[0]) {
                          // if (value && value[0] && upload1 && upload1.length > 0) {
                          //   return "Please select either logo or banner";
                          // }
                          if (!value || !value[0]) return "Property image is required";
                          const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
                          if (!allowedTypes.includes(value[0].type)) {
                            return "Only images (JPEG, PNG) are allowed";
                          }
                          return true;
                        }
                      },
                    })}
                    type="file"
                    className="d-none"
                    data-index={1}
                    accept="image/*"
                  />
                  <img
                    src={previewFile2 ? previewFile2 : "assets/images/adv-banner.svg"}
                    alt=""
                    className="w-100"
                  />
                </label>
                {previewFile2 && (
                  <img
                    onClick={handleResetFile2}
                    src="assets/images/cross.svg"
                    className="cross-icon"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
          <span style={{ color: "red" }}>
            {errors?.upload2?.message ||
              (errors?.upload1?.message && (
                <span role="alert" style={{ color: "red" }}>
                  {errors?.upload2?.message || errors?.upload1?.message}
                </span>
              ))}
          </span>
        </div>
        <div className="form-group">
          <label>
            Email<span className="text-danger">*</span>
          </label>
          <input
            type="email"
            placeholder="Email"
            {...register("advemail", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Please enter a valid email address",
              },
            })}
          />
          {errors.advemail && <span className="text-danger">{errors.advemail.message}</span>}
        </div>
        <div className="form-group">
          <label> Contractor Type</label>
          <select
            {...register("professionalDesignation", {
              required: "Contractor type is required",
              validate: (value) => value.trim().length > 0 || "Contractor type is required",
            })}
            placeholder="Contractor Type"
          >
            <option value={""} selected disabled>
              Select contractor type
            </option>
            {designationData?.map((ele) => {
              return (
                <optgroup label={ele.label}>
                  {ele.options.map((e) => {
                    return <option value={e?.slug}>{e?.title}</option>;
                  })}
                </optgroup>
              );
            })}
          </select>
          {errors.professionalDesignation && (
            <span role="alert" style={{ color: "red" }}>
              {errors.professionalDesignation.message}
            </span>
          )}
        </div>
        <div className="d-grid gap-2 reapt-2">
          <div className="form-group">
            <label>
              Company Name<span className="text-danger">*</span>
            </label>
            <input
              maxLength={50}
              type="text"
              placeholder="Company name"
              {...register("companyName", {
                required: "Company name is required",
                validate: (value) => value.trim().length > 0 || "Company name is required",
              })}
            />
            {errors.companyName && (
              <span className="text-danger">{errors.companyName.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Contact Name <span className="text-danger">*</span>
            </label>
            <input
              maxLength={50}
              placeholder="Contact name"
              type="text"
              {...register("contactName", {
                required: "Contact name is required",
                validate: (value) => value.trim().length > 0 || "Contact name is required",
              })}
            />
            {errors.contactName && (
              <span className="text-danger">{errors.contactName.message}</span>
            )}
          </div>
        </div>
        {/* <div className="form-group">
          <label>
            Zip Code<span className="text-danger">* </span>
          </label>
          <input
            maxLength={10}
            {...register("zipCode", {
              required: "Zip code is required",

              maxLength: {
                value: 10,
                message: "Zip code must not be greater than 10 digits",
              },
              validate: (value) => value.trim().length > 0 || "Zip code is required",
            })}
            type="text"
            placeholder="Zip code"
          />
          {errors.zipCode && <span className="text-danger">{errors.zipCode.message}</span>}
        </div> */}
        <div className="d-grid gap-2 reapt-2">
          <div className="form-group">
            <label>
              Phone Number<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="Phone number"
              maxLength={10}
              {...register("phoneNumber", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "Please enter a valid phone number",
                },
                minLength: {
                  value: 10,
                  message: "Phone no. must be 10 digits",
                },
                maxLength: {
                  value: 10,
                  message: "Phone no. must be 10 digits",
                },
                validate: (value) =>
                  value.trim().length === 10 || "Password must be at least 10 characters long",
              })}
            />
            {errors.phoneNumber && (
              <span className="text-danger">{errors.phoneNumber.message}</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Company Email<span className="text-danger">*</span>
            </label>
            <input
              type="email"
              placeholder="Company email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please enter a valid email address",
                },
              })}
            />
            {errors.email && <span className="text-danger">{errors.email.message}</span>}
          </div>
        </div>
        <div className="form-group">
          <label>
            Company Website<span className="text-danger"></span>
          </label>
          <input
            placeholder="https://www.yourcompany.com"
            {...register("companyWebsite", {
              // required: "Company website is required",
              pattern: {
                value:
                  /^(ftp|https?):\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\.[a-zA-Z]{2,})?(:[0-9]{1,5})?(\/.*)?$/,
                message: "Please enter a valid website URL",
              },
            })}
            type="url"
          />
          {errors.companyWebsite && (
            <span className="text-danger">{errors.companyWebsite.message}</span>
          )}
        </div>
        <div className="form-group w-100">
          <label>
            Select your subscription package? <sup>*</sup>
            <span>(See example)</span>
          </label>
          <select
            // onChange={() => {
            //   setPostalCodes(new Set());
            //   setVerifyZipCodeMap({});
            //   setVerifyZipCode({});
            //   setUpdate([]);
            // }}
            {...register("subscriptionPackage", {
              required: "Subscription Package is required",
              validate: (value) => value.trim().length > 0 || "Subscription Package is required",
            })}
          >
            <option value={""}>Select subscription package</option>
            {paymentPackage.map((ele) => {
              return <option value={ele.key}>{ele.label}</option>;
            })}
          </select>
          {errors.subscriptionPackage && (
            <span style={{ color: "red" }}>{errors.subscriptionPackage.message}</span>
          )}

          <div>
            <p
              style={{
                textDecoration: "underline",
                color: packageSelected && contractorType ? "black" : "grey",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => {
                if (packageSelected && contractorType) {
                  setShowModal(true);
                } else {
                  toastAlert(
                    `Please select ${!contractorType ? "contractor type" : "Package"} for Ads`,
                    "error"
                  );
                  // if (!contractorType) {
                  // } else {
                  //   toastAlert("Please select Package for Ads", "error");
                  // }
                }
              }}
              className="mt-4 work-type"
            >
              View available zip codes
            </p>
            {/* <div className="mt-2">{verifyZipCode && <PincodeList data={verifyZipCode} />}</div> */}
          </div>
        </div>
        <div className="form-group">
          <label>
            Desired Zip Codes for Logo Ads<span className="text-danger">* </span>
          </label>

          <div className="mt-2 ">
            <div>{verifyZipCode && <PincodeList data={verifyZipCode} />}</div>
          </div>
        </div>

        <div>
          <label className="mb-2">Which of your zip codes do you want a banner ad?</label>
          <p style={{ fontSize: "12px", color: "grey" }}>
            The below zip codes are available for Banner Ad's. Select any zip codes where you would
            like for a banner ad to display
          </p>
          <div>{verifyZipCode && <BannerPincodeList data={verifyZipCode} />}</div>
        </div>
        <div className="form-group mt-4">
          <button disabled={disableBtn} type="submit" className="primery-btn">
            Add
          </button>
        </div>
      </form>
      <MapModal
        update={update}
        setUpdate={setUpdate}
        verifyZipCodeMap={verifyZipCodeMap}
        packageDetails={packageDetails}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        postalCodes={postalCodes}
        setPostalCodes={setPostalCodes}
        handler={AddPostalCodes}
        heading={"Zip Code"}
        // body={"Are you sure you want to delete this elevation template?"}
      />
    </>
  );
};

export default CreateAdvertisers;
