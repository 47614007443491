// export function formatDate(inputDateString) {
//   const inputDate = new Date(inputDateString);
// const options = { year: "numeric", month: "long", day: "numeric" };
// return inputDate.toLocaleDateString("en-US", options);
// }
// export function formatDate(inputDateString, type) {
//   const inputDate = new Date(inputDateString);

//   if (type === "date-time") {
//     const options = {
//       year: "numeric",
//       month: "long",
//       day: "numeric",
//       hour: "numeric",
//       minute: "numeric",
//       second: "numeric",
//     };
//     return inputDate.toLocaleDateString("en-US", options);
//   } else {
//     const options = { year: "numeric", month: "long", day: "numeric" };
//     return inputDate.toLocaleDateString("en-US", options);
//   }
// }
// export function formatDate(inputDateString, type) {
//   const inputDate = new Date(inputDateString);

//   if (type === "date-time") {
//     const options = {
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//       hour: "numeric",
//       minute: "numeric",
//       second: "numeric",
//     };
//     return inputDate.toLocaleDateString("en-US", options).replace(/\//g, "-");
//   } else {
//     const options = { year: "numeric", month: "2-digit", day: "2-digit" };
//     return inputDate.toLocaleDateString("en-US", options).replace(/\//g, "-");
//   }
// }

export function formatDate(inputDateString, type) {
  const inputDate = new Date(inputDateString);

  if (type === "date-time") {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return inputDate.toLocaleDateString("en-US", options);
  } else {
    const month = inputDate.toLocaleDateString("en-US", { month: "short" });
    const day = inputDate.getDate();
    const year = inputDate.getFullYear();

    return `${month} ${day}, ${year}`;
  }
}
