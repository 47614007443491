import React, { useState } from "react";
import DeveloperList from "./DeveloperList";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import InviteAgentBuilderForm from "./InviteAgentBuilderForm";

const Home = () => {
  const [show, setShow] = useState(false);
  const [formView, setFormView] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRefresh,setPageRefresh]=useState(false)
  const handleClose = () => setShow(false);

  const handleShow = (form) => {
    setFormView(form);
    setShow(true);
  };

  const handleFormView = () => {
    if (formView === "inviteAgent") {
      return (
        <>
          <Offcanvas.Header>
            <Offcanvas.Title>
              <h3>
                <Link onClick={handleClose} to="#">
                  <img src="assets/images/left-arrow.svg" alt="" />
                </Link>
                Invite Agent/ Builder
              </h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <InviteAgentBuilderForm
              handleClose={handleClose}
              setPageRefresh={setPageRefresh}
              pageRefresh={pageRefresh}
              setCurrentPage={setCurrentPage}
            />
          </Offcanvas.Body>
        </>
      );
    }
  };
  return (
    <>
      <div className="home">
        <div className="container d-flex">
          <div className="left">
            <h2>What do you want to do?</h2>
            <div onClick={() => handleShow("inviteAgent")} className="invite">
              <img src="assets/images/invite.png" alt="" />
              <h4>Invite a Builder/ Agent</h4>
            </div>
            <div className="invite">
              <img src="assets/images/help-request.png" alt="" />
              <h4>Help Requests</h4>
            </div>
          </div>
          <div className="right">
            <div className="title d-flex justify-content-between align-items-center">
              <h3>All Builders/ Agents</h3>
              <input
                type="search"
                placeholder="Search for a builder or agent"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </div>
            <DeveloperList
              pageRefresh={pageRefresh}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              search={search}
            />
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} backdrop={true} placement="end ">
        {handleFormView()}
      </Offcanvas>
    </>
  );
};

export default Home;
