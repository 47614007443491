import axios from "axios";
import { logoutUser } from "../utils/logoutUser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCookie } from "../utils/cookies";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_DREES_HOME_LOCAL_URL,
  headers: { "x-api-release": 5 },
});
let isInternetAlertShown = false;
let isGenericErrorAlertShown = false;

const showInternetAlert = () => {
  if (!isInternetAlertShown) {
    toast.error("No internet connection. Please check your connection.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    isInternetAlertShown = true;
  }
};

const showGenericErrorAlert = (error) => {
  if (!error.response?.data?.message) {
    toast.error("An error occurred. Please try again later.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    isGenericErrorAlertShown = true;
  }
};

Axios.interceptors.request.use((config) => {
  isInternetAlertShown = false; // Reset the flag for each new request

  const token = JSON.parse(getCookie("suser_data"))?.token;

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  if (!window.navigator.onLine) {
    showInternetAlert();
    return Promise.reject(new axios.Cancel("No internet connection"));
  }

  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error instanceof axios.Cancel) {
      showInternetAlert();
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      if (error?.response?.data?.message === "Unauthenticated.") {
        if (!isGenericErrorAlertShown) {
          toast.error("Session expired. Please log in again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          isGenericErrorAlertShown = true;
          logoutUser();
        }
      }
    }

    showGenericErrorAlert(error);

    return Promise.reject(error);
  }
);

export default Axios;
