import React from "react";

const MoreInfo = ({ propertyData }) => {
  return (
    <>
      <div className="more-info">
        <span>More Info</span>
        <p>
          <span>Year Built</span>
          <b>{propertyData?.year ? propertyData?.year : <span>Information not provided</span>}</b>
        </p>
        <p>
          <span>Builder</span>
          <b>
            {propertyData?.builder ? propertyData?.builder : <span>Information not provided</span>}
          </b>
        </p>
        <p>
          <span>Address</span>
          <b>
            {propertyData?.address ? (
              <>
                {propertyData?.address}
                {propertyData?.zipcode ? `, ${propertyData?.zipcode}` : ""}
              </>
            ) : (
              <span>Information not provided</span>
            )}
          </b>
        </p>
        <p>
          <span>Project Manager</span>
          <b>
            {propertyData?.project_manager ? (
              propertyData?.project_manager
            ) : (
              <span>Information not provided</span>
            )}
          </b>
        </p>
        <p>
          <span>Relationship Manager</span>
          <b>
            {propertyData?.relationship_manager ? (
              propertyData?.relationship_manager
            ) : (
              <span>Information not provided</span>
            )}
          </b>
        </p>
        <p>
          <span>Relationship Manager Email</span>
          <b>
            {propertyData?.relationship_manager_email ? (
              propertyData?.relationship_manager_email
            ) : (
              <span>Information not provided</span>
            )}
          </b>
        </p>
      </div>
    </>
  );
};

export default MoreInfo;
