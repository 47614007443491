import React, { useEffect, useState } from "react";
import MoreInfo from "./MoreInfo";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import RoomsAndFeatures from "./propertyDetails/RoomsAndFeatures";
import Contractors from "./propertyDetails/Contractors";
import SupportStaffDetails from "./propertyDetails/SupportStaffDetails";
// import PropertyDetails from "./propertyDetails/PropertyDetails";
import useLoader from "../../component/loader/useLoader";
import Axios from "../../axios/axiosInstance";
import { toastAlert } from "../../utils/toastAlert";

const PropertyDashboard = () => {
  const { showLoader, hideLoader, Loader } = useLoader();
  const location = useLocation();
  const { id, userid } = useParams();
  const [propertyData, setPropertyData] = useState();
  const [show, setShow] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [allocationStatus, setAllocationStatus] = useState(false);
  const [ContractorsAndStaffData, setContractorsAndStaffData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!show) {
      getMoreInfo();
      getContractorsAndStaffData();
    }
  }, [pageRefresh]);

  const getMoreInfo = () => {
    showLoader();
    Axios.get(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/${userid}/properties/${id}?rooms&features`
    )
      .then((res) => {
        setPropertyData(res.data.property);
        console.log(res.data.property);

        if (res.data.property.allocation !== null) {
          setAllocationStatus(true);
        } else {
          setAllocationStatus(false);
        }
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  const getContractorsAndStaffData = () => {
    showLoader();
    Axios.get(
      `${process.env.REACT_APP_DREES_HOME_LOCAL_URL}admin/${userid}/properties/${id}/contacts?type`
    )
      .then((res) => {
        setContractorsAndStaffData(res.data.contacts.data);
      })
      .catch((err) => {
        toastAlert(err?.response?.data?.message, "error");
      })
      .finally((res) => {
        hideLoader();
      });
  };

  console.log("location ss", location.state);
  return (
    <>
      <div className="alocated-property">
        <div className="container">
          <div className="upload-title d-flex">
            <div className="backlink">
              {/* <Link onClick={() => navigate(-1)}>
                <img src="assets/images/left-arrow.svg" alt="" />{" "}
                {propertyData?.name}
              </Link> */}
              {location?.state?.isAll ? (
                <Link
                  to={`/all-properties/user/${location?.state?.devId}?page=${location?.state?.page}&search=${location?.state?.search}&allocated=${location?.state?.allocated}`}
                >
                  <span>
                    {" "}
                    <img src="assets/images/left-arrow.svg" alt="" />{" "}
                  </span>
                  <span>
                    <b>{propertyData?.address}</b>
                    {propertyData?.name && <p>{propertyData?.name}</p>}
                  </span>
                </Link>
              ) : (
                <Link onClick={() => navigate(-1)}>
                  <span>
                    {" "}
                    <img src="assets/images/left-arrow.svg" alt="" />{" "}
                  </span>
                  <span>
                    <b>{propertyData?.address}</b>
                    {propertyData?.name && <p>{propertyData?.name}</p>}
                  </span>
                </Link>
              )}
            </div>
            {/* <Link class="alocated" href="/home">
              <img src="assets/images/Wavy_Check.svg" alt="" /> Allocated
            </Link> */}
            {allocationStatus ? (
              <Link className="alocated">
                <img src="assets/images/Wavy_Check.svg" alt="" /> Allocated
              </Link>
            ) : (
              <Link to="#" className="unalocated">
                <img src="assets/images/unalocated.svg" alt="" /> Unallocated
              </Link>
            )}
          </div>
          <div className="d-flex owner-box">
            <div className="left">
              {propertyData?.image ? (
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}${propertyData?.image}`}
                  alt="dress"
                  className="w-100 mb-4 propsImg"
                />
              ) : (
                <img
                  src="assets/images/dress.svg"
                  className="w-100 mb-4 propsImg"
                  alt="dress"
                />
              )}

              <div className="bg-set">
                <p>Allocated to</p>
                <div className="alocated-to d-flex">
                  <span className="owner">
                    {propertyData?.allocation?.image ? (
                      <img
                        src={`${process.env.REACT_APP_MEDIA_URL}${propertyData?.allocation?.image}`}
                        alt=""
                      />
                    ) : (
                      <img src="assets/images/barry.svg" />
                    )}
                  </span>
                  <span className="owner-name">
                    <h4>
                      {propertyData?.allocation?.name
                        ? propertyData?.allocation?.name
                        : "N/A"}
                    </h4>
                    <span>{propertyData?.allocation?.email}</span>
                    {/* <h6>{propertyData?.allocation?.email}</h6> */}
                    <p>Home Owner</p>
                  </span>
                </div>
                <MoreInfo propertyData={propertyData} />
              </div>
            </div>

            <div className="right">
              <p>Property Details</p>
              <Tabs
                defaultActiveKey="rooms"
                id="uncontrolled-tab-example"
                className="property-tab mb-4"
              >
                <Tab
                  eventKey="rooms"
                  title={
                    <span>
                      Rooms & Features <span>{propertyData?.rooms_count}</span>
                    </span>
                  }
                >
                  <RoomsAndFeatures
                    userid={userid}
                    propertyId={id}
                    roomList={propertyData?.rooms}
                  />
                </Tab>
                <Tab
                  eventKey="Contractors"
                  title={
                    <span>
                      Contractors <span>{propertyData?.contractors_count}</span>
                    </span>
                  }
                >
                  <Contractors
                    ContractorsAndStaffData={ContractorsAndStaffData}
                  />
                </Tab>
                <Tab
                  eventKey="support"
                  title={
                    <span>
                      Support Staff Details{" "}
                      <span>{propertyData?.support_count}</span>
                    </span>
                  }
                >
                  <SupportStaffDetails
                    ContractorsAndStaffData={ContractorsAndStaffData}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      {/* PropertyDashboard2
  <MoreInfo/>
  <PropertyDetails/> */}
    </>
  );
};

export default PropertyDashboard;
