import React, { useState } from "react";
import { InfinitySpin } from "react-loader-spinner";

const useLoader = () => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => {
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
  };

  const Loader = ({
    type = "Puff",
    color = "#045C46",
    height = 100,
    width = 200,
  }) => {
    return isLoading ? (
      <div className="loader-overlay">
        <div className="loader-container">
          <InfinitySpin color={color} height={height} width={width} />
        </div>
      </div>
    ) : null;
  };

  return { showLoader, hideLoader, Loader };
};

export default useLoader;
