import { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toastAlert } from "../../utils/toastAlert";

const MapModal = ({
  postalCode,
  disableADDBtn,
  postalCodes,
  setPostalCodes,
  showModal,
  handleCloseModal,
  handler,
  body,
  packageDetails,
  heading,
  verifyZipCodeMap,
  update,
  setUpdate,
}) => {
  let map;
  let featureLayer;
  let infoWindow;

  const mapContainerRef = useRef(null);
  const [markerReferences, setMarkerReferences] = useState({});

  const markerRef = useRef(null);
  console.log("p detailsssss in map", packageDetails);
  const [mapInitialized, setMapInitialized] = useState(false);

  let arr = [];

  const zipcodeTabs = update?.map((code) => {
    console.log("chekc zipcode tabs");
    return (
      <span key={code}>
        {code}
        <img
          style={{ cursor: "pointer" }}
          src="assets/images/cross.svg"
          alt="Delete"
          onClick={() => deletePostalCode(code)}
        />
      </span>
    );
  });

  const handleClick = (e) => {
    if (!disableADDBtn) {
      if (postalCodes.size < packageDetails.zipcodes || packageDetails.zipcodes === 6) {
        if (!Array.isArray(e.features) || e.features.length === 0) {
          infoWindow.close();
          return;
        }

        const placeId = e.features[0].placeId;

        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails(
          {
            placeId: placeId,
          },
          function (result, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
              console.error("Place details request failed:", status);
              return;
            }

            const postal = result.address_components.find((item) =>
              item.types.includes("postal_code")
            );

            if (postal) {
              // addMarker(result.geometry.location, postal.short_name);
              addPostalCode(result.geometry.location, postal.short_name);
            }
            // createInfoWindow(e);
          }
        );
      } else {
        toastAlert(
          `only ${packageDetails.zipcodes} ${
            packageDetails.zipcodes === 1 ? "zipcode is" : "zipcodes are"
          } allowed`,
          "error"
        );
      }
    }
  };
  useEffect(() => {
    const createPinCodeNode = (pin) => {
      const pinWrapper = document.createElement("div");
      pinWrapper.classList.add("pinWrapper");
      pinWrapper.textContent = pin;
      return pinWrapper;
    };

    const markers = [];
    const markedplaces = new Set();
    const updateMarkers = (feature) => {
      if (map.getZoom() < 10) {
        return;
      }
      const placeId = feature.placeId;
      if (markedplaces.has(placeId)) {
        return;
      }
      markedplaces.add(placeId);
      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(
        {
          placeId: placeId,
        },
        function (result, status) {
          if (status != window.google.maps.places.PlacesServiceStatus.OK) {
            markedplaces.delete(placeId);
            return;
          }
          const postal = result.address_components.find((item) =>
            item.types.includes("postal_code")
          );
          if (!postal) {
            markedplaces.delete(placeId);
          }
          const node = createPinCodeNode(postal.short_name);
          markers.push({
            m: new window.AdvancedMarkerElement({
              map,
              position: result.geometry.location,
              content: node,
            }),
            p: placeId,
          });
          //        console.log(result)
        }
      );
    };

    const styleDefault = {
      strokeColor: "#810FCB",
      strokeOpacity: 1.0,
      strokeWeight: 2.0,
      fillColor: "white",
      fillOpacity: 0.1,
    };

    const applyStyle = (params) => {
      updateMarkers(params.feature);

      return styleDefault;
    };

    const initMap = async () => {
      if (window.google && window.google.maps) {
        const { Map, InfoWindow } = await window.google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
        window.AdvancedMarkerElement = AdvancedMarkerElement;
        const bounds = new window.google.maps.LatLngBounds();
        map = new Map(mapContainerRef.current, {
          center: { lat: 39.8283, lng: -98.5795 },
          zoom: 11,
          minZoom: 9,
          mapId: "347e8a14914b5afb",
          mapTypeControl: false,
        });

        // Initialize the Autocomplete search input
        const input = document.getElementById("autocomplete-input");
        const autocomplete = new window.google.maps.places.Autocomplete(input);

        // Add a listener for when a place is selected
        autocomplete.addListener("place_changed", () => {
          handlePlaceSelection(autocomplete.getPlace());
        });

        map.addListener("idle", () => {
          for (const marker of markers) {
            if (map.getBounds().contains(marker.m.position)) {
              marker.m.map = map;
            } else if (!map.getBounds().contains(marker.m.position)) {
              marker.m.map = null;
            }
          }
        });

        map.addListener(
          "click",
          (e) => {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: e.latLng }, (results, status) => {
              if (status === "OK") {
                handlePlaceSelection(results[0]);
              } else {
                console.error("Geocode was not successful for the following reason: " + status);
              }
            });
          },
          { passive: true }
        );

        featureLayer = map.getFeatureLayer("POSTAL_CODE");
        featureLayer.addListener("click", handleClick);

        infoWindow = new InfoWindow({});
        featureLayer.style = applyStyle;

        const showPredefinedMarkers = () => {
          if (update && update?.length > 0) {
            // const bounds = new window.google.maps.LatLngBounds();
            update.forEach((code) => {
              const geocoder = new window.google.maps.Geocoder();
              geocoder.geocode({ address: code }, (results, status) => {
                if (status === "OK") {
                  for (const item of results[0].address_components) {
                    if (item.types.includes("postal_code")) {
                      addMarker(results[0].geometry.location, item.short_name, true);
                    } else {
                      console.error(`Geocode for ${code} failed with status: ${status}`);
                    }
                  }
                } else {
                  console.error(`Geocode for ${code} failed with status: ${status}`);
                }
              });
            });
          }
        };

        showPredefinedMarkers();
      } else {
        console.error("Google Maps API not loaded");
      }
    };

    // const createInfoWindow = async (event) => {
    //   if (!Array.isArray(event.features) || !event.features.length) {
    //     // shoud handle location click here
    //     return;
    //   }
    //   const placeId = event.features[0].placeId;

    //   const service = new window.google.maps.places.PlacesService(map);
    //   service.getDetails(
    //     {
    //       placeId: placeId,
    //     },
    //     function (result, status) {
    //       if (status != window.google.maps.places.PlacesServiceStatus.OK) {
    //         return;
    //       }

    //       const address = { state: null, postcode: null };
    //       for (const item of result.address_components) {
    //         if (item.types.includes("postal_code")) {
    //           address.postcode = item.short_name;
    //         } else if (item.types.includes("administrative_area_level_1")) {
    //           address.state = item.short_name;
    //         }
    //       }

    //     //   const content = `<span style="font-size:small">${result.formatted_address}
    //     // <br/> Place ID: ${placeId}
    //     // <br/> Feature type: POSTAL_CODE
    //     // <br/> Postal Code: ${address.postcode || ""}</span>`;

    //     //   updateInfoWindow(content, result.geometry.location);
    //     }
    //   );
    // };
    // const createInfoWindowOld = async (event) => {
    //   const feature = event.features[0];
    //   const clickedLocation = event.latLng;

    //   if (!feature.placeId) return;

    //   var geocoder = new window.google.maps.Geocoder();
    //   geocoder.geocode({ location: clickedLocation }, function (results, status) {
    //     if (status === "OK") {
    //       if (results[0]) {
    //         var addressComponents = results[0].address_components;
    //         let state, postcode;
    //         for (let i = 0; i < addressComponents.length; i++) {
    //           const types = addressComponents[i].types;
    //           if (types.includes("administrative_area_level_1")) {
    //             state = addressComponents[i].short_name;
    //           } else if (types.includes("postal_code")) {
    //             postcode = addressComponents[i].short_name;
    //           }
    //         }
    //         // if (postcode) {
    //         //   addPostalCode(postcode);
    //         // }

    //         const pincodeContent = postcode ? `<br/> Pincode: ${postcode}` : "";
    //         const place = results[0].formatted_address;
    //         let content =
    //           `<span style="font-size:small">Display name: ${place}` +
    //           `<br/> Place ID: ${feature.placeId}` +
    //           `<br/> Feature type: ${feature.featureType}` +
    //           `${pincodeContent}</span>`;

    //         updateInfoWindow(content, event.latLng);
    //       } else {
    //         console.error("No results found");
    //       }
    //     } else {
    //       console.error("Geocoder failed due to:", status);
    //     }
    //   });
    // };

    // const updateInfoWindow = (content, center) => {
    //   infoWindow.setContent(content);
    //   infoWindow.setPosition(center);
    //   infoWindow.open({
    //     map,
    //     shouldFocus: false,
    //   });
    // };

    const handlePlaceSelection = (place) => {
      if (place?.geometry) {
        map?.panTo(place.geometry.location);
        map?.setZoom(15);

        // Fetch the postal code for the selected place
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: place?.geometry?.location }, (results, status) => {
          if (status === "OK") {
            for (const component of results[0]?.address_components) {
              if (component?.types.includes("postal_code")) {
                if (postalCodes.size < packageDetails.zipcodes || packageDetails.zipcodes === 6) {
                  // addMarker(place.geometry.location, component?.short_name);
                  addPostalCode(place.geometry.location, component?.short_name);
                } else {
                  toastAlert(`only ${packageDetails.zipcodes} zipcodes are allowed`, "error");
                }
                break;
              }
            }
          } else {
            console.error("Geocode was not successful for the following reason: " + status);
          }
        });
      }
    };

    initMap();
  }, [showModal]);

  const addPostalCode = (position, code) => {
    if (!disableADDBtn) {
      if (postalCodes.size < packageDetails.zipcodes || packageDetails.zipcodes === 6) {
        if (postalCodes.size >= 0 && !postalCodes.has(code)) {
          arr.push(code);
          postalCodes.add(code);
          setUpdate([...postalCodes]);
          addMarker(position, code);
          console.log("afterrrrr", postalCodes, code);
        }
      }
    }
  };

  let m = [];

  const addMarker = (position, code, pre) => {
    // console.log("mapo", m);
    // if (m.includes(code) === false) {
    // No duplicate found, add the marker
    console.log("my", markerReferences);
    const marker = new window.google.maps.Marker({
      position: position,
      map: map,
      title: "Selected Location",
      icon: "",
    });

    markerRef.current = marker;
    if (!pre) {
      m.push(code);
    }

    // Store the marker reference along with the postal code
    setMarkerReferences((prevMarkers) => ({
      ...prevMarkers,
      [code]: marker,
    }));

    const toggleMarker = (marker) => {
      if (marker.getMap() === null) {
        marker.setMap(map);
      } else {
        marker.setMap(null);
      }
    };

    marker.addListener("click", () => {
      deletePostalCode(code);
      toggleMarker(marker);
    });
    // } else {
    //   // Duplicate found, delete the existing marker and code
    //   const existingMarker = markerReferences[code];
    //   if (existingMarker) {
    //     existingMarker.setMap(null); // Remove the marker from the map
    //   }

    //   deletePostalCode(code); // Delete the postal code
    // }
  };

  const deletePostalCode = (code) => {
    console.log("mapo", m, "delete");
    m = m.length > 0 && m.filter((c) => c !== code);

    postalCodes.delete(code);
    setUpdate([...postalCodes]);

    // Remove the associated marker from the map
    const markerToRemove = markerReferences[code];
    if (markerToRemove) {
      markerToRemove.setMap(null);
    }

    setMapInitialized(!mapInitialized);
  };


  const PincodeList = ({ data }) => {
    return (
      <div className="zip d-flex flex-column">
        <div className="zip_code_container">
          <div className=" d-flex flex-column">
            <p>Available zipcodes</p>
            <div>
              {Object.keys(data?.available)
                .filter((pincode) => data?.available[pincode]?.logo)
                .map((pincode) => (
                  <span
                    className="grayPin"
                    key={pincode}
                    style={{
                      backgroundColor: "#2A9D4A1A",
                      cursor: "pointer",
                    }}
                  >
                    {pincode}
                  </span>
                ))}

              {data.notInUse.map((pincode) => (
                <span
                  key={pincode}
                  style={{
                    backgroundColor: "#2A9D4A1A",
                    cursor: "pointer",
                  }}
                >
                  {pincode}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="zip_code_container d-flex flex-column mt-2">
          <p>Not available zipcodes</p>
          <div>
            {Object.keys(data?.available)
              .filter((pincode) => !data?.available[pincode]?.logo)
              .map((pincode) => (
                <span
                  className="grayPin"
                  key={pincode}
                  style={{
                    color: "grey",
                    cursor: "pointer",
                  }}
                >
                  {pincode}
                </span>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        size="xl"
        centered
        show={showModal}
        onHide={handleCloseModal}
        className="delete-modal modalMap"
      >
        {heading && (
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <div>
            {!disableADDBtn && (
              <div>
                <input id="autocomplete-input" type="text" placeholder="Enter a location" />
              </div>
            )}
            <div ref={mapContainerRef} className="modalView-map"></div>
            <div>
              <div className="zip d-flex my-3">
                <div className="zip_code_container">{zipcodeTabs}</div>
              </div>
            </div>
          </div>
          <div>
            {verifyZipCodeMap &&
              verifyZipCodeMap.available &&
              Object.keys(verifyZipCodeMap.available).length > 0 && (
                <PincodeList data={verifyZipCodeMap} />
              )}
          </div>
        </Modal.Body>
        <div className="footerModal text-center">
          {/* <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button> */}
          {!disableADDBtn && (
            <button
              disabled={update && update.length > 0 ? false : true}
              style={{ backgroundColor: update && update.length > 0 ? "" : "grey" }}
              className="btnc btn_minW"
              onClick={handler}
            >
              ADD
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MapModal;
